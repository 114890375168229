import React, { createContext } from "react";
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import getCookie from '../functions/getCookie';
import setCookie from '../functions/setCookie';
import resetCookie from '../functions/resetCookie';

const Context = createContext();

const NecessaryCustomerFields = [
    'firstName',
    'lastName',
    'email',
    'streetAndNumber',
    'city',
    'country',
    'zipcode',
    'officeLocation'
];

const NotSelfPaidCustomerFields = [
    'email',
    'officeLocation'
];

const CustomContextProvider = (props) => {
    const [customer, setCustomer] = React.useState({});
    const [cart, setCart] = React.useState({});
    const [token, setToken] = React.useState(null);
    const [userEmail, setUserEmail] = React.useState(null);
    const [customerIsComplete, setCustomerIsComplete] = React.useState(false);
    const [ssoRedirectError, setSsoRedirectError] = React.useState(null);
    const [logoutUrl, setLogoutUrl] = React.useState(null);
    const [footerJson, setFooterJson] = React.useState(null);
    const [footerJsonLoaded, setFooterJsonLoaded] = React.useState(false);
    const [clearFrontendStateOnLogout, setClearFrontendStateOnLogout] = React.useState(false);
    const faqUrl = '/content/faq';

    React.useEffect(() => {
        if (token === null || userEmail === null) {
            checkToken();
        }

        if (token !== null && userEmail !== null) {
            urlFetch('/api/carts', { successCallback: setCart }, null, 'POST', null, { email: userEmail }, token);
            urlFetch('/api/customers', { successCallback: updateCustomer }, null, 'POST', null, { email: userEmail }, token);
            urlFetch('https://footer.brainlab.com/wp-json/footer-menu/v1/footer/40?domain=brandshop', { serverErrorCallback: (error) => { console.log('server error', error); }, clientErrorCallback: (error) => { console.log('client error', error); }, successCallback: (response) => { setFooterJsonLoaded(true); setFooterJson(response); }});
        }
    }, [token, userEmail, footerJsonLoaded]); // eslint-disable-line

    /**
     * Customer handling
     */

    const getIsCustomerComplete = (customer, isSelfPaid = "self") => {
        const completionFlags = {};
        const elements = isSelfPaid === "self" ? NecessaryCustomerFields.length : NotSelfPaidCustomerFields.length;
        for (let i = 0; i < elements; i += 1) {
            const key = isSelfPaid === "self" ? NecessaryCustomerFields[i] : NotSelfPaidCustomerFields[i];
            const hasKey = key in customer;

            completionFlags[key] = hasKey && !!customer[key];
        }

        if ('officeLocation' in customer && 'availableOfficeLocations' in customer) {
            const availableLocationValues = customer['availableOfficeLocations'].map((entry) => {
                return entry['value'];
            });

            completionFlags['officeLocation'] = availableLocationValues.indexOf(customer['officeLocation']) > -1;
        }

        return !(Object.values(completionFlags).includes(false));
    }

    const updateCustomer = (customer) => {
        const completion = getIsCustomerComplete(customer);

        setCustomerIsComplete(completion);
        setCustomer(customer);
    }

    /**
     * SSO handling
     */

    const tokenCheckCallback = (response) => {
        const ssoUrl = typeof response.sso_url !== 'undefined' ? response.sso_url : null;

        if (typeof response.token === 'undefined' && ssoUrl === null) {
            setSsoRedirectError('Can not redirect to SSO Login (SSO URL is missing) - contact your admin');

            return;
        }

        if (typeof response.token === 'undefined' && ssoUrl !== null) {
            redirectToSSOLogin(ssoUrl);

            return;
        }

        setUserEmail(response.email);
        setToken(response.token);

        if (typeof response.sso_url !== 'undefined') {
            setLogoutUrl(response.logout_url);
        }

        if (typeof response.clear_frontend_state_on_logout !== 'undefined') {
            setClearFrontendStateOnLogout(response.clear_frontend_state_on_logout);
        }
    }

    const getTokenFromUrlOrCookie = () => {
        const url = new URL(window.location.href);
        let token = null;

        if (getCookie('token') !== '') {
            token = getCookie('token');
        }

        if (token === null && url.searchParams.get('token')) {
            token = url.searchParams.get('token');
            setCookie('token', token, 1/24);
        }

        url.searchParams.delete('token');
        window.history.replaceState({}, '', url.toString());

        return token;
    }

    const getSSOURLFromUrl = () => {
        const url = new URL(window.location.href);
        const ssoUrl = url.searchParams.get('sso_url');

        url.searchParams.delete('sso_url');
        window.history.replaceState({}, '', url.toString());

        return ssoUrl;
    }

    const checkToken = () => {
        const token = getTokenFromUrlOrCookie();
        const ssoUrl = getSSOURLFromUrl();

        if (token === null && ssoUrl !== null) {
            redirectToSSOLogin(ssoUrl);

            return;
        }

        urlFetch('/api/check-token', {
            successCallback: tokenCheckCallback,
        }, null, 'POST', null, { token, target_url: window.location.href });
    }

    const redirectToSSOLogin = (url) => {
        window.location.href = url;
    }

    const logout = () => {
        resetCookie('token');

        if (clearFrontendStateOnLogout) {
            setToken(null);
            setCustomer(null);
            setUserEmail(null);
        }

        if (logoutUrl !== null) {
            window.location.href = logoutUrl;
        }
    }

    /**
     * Children + rendering
     */

    let children = token ? props.children : null;

    if (children === null && ssoRedirectError !== null) {
        children = <div>{ssoRedirectError}</div>;
    }

    return (
        <Context.Provider value={{token, customer, cart, userEmail, customerIsComplete, getIsCustomerComplete, updateCustomer, updateCart: setCart, logout, footerJson, faqUrl}}>
            {children}
        </Context.Provider>
    )
}

export { CustomContextProvider, Context };
export default Context.Consumer;
