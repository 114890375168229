import styled from "styled-components";

const ImageWrapper = styled('div')`
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

export default ImageWrapper;
