import React from "react";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Popup from '../../02_atoms/Popup';
import Button from '../../02_atoms/Button';
import { ReactComponent as ButtonArrow } from '../../../icons/arrow-right-long.svg';

const StyledHeadlineContainer = styled('div')`
    width: 100%;
    margin-bottom: 10px;
    font-size: 24px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        margin-bottom: 30px;
        font-size: 26px;
    }
`;

const StyledBodyContainer = styled('div')`
    display: flex;
    flex-direction: column;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
    }
`;

const StyledContentContainer = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledButtonsContainer = styled('div')`
    margin-top: auto;
    display: flex;
    justify-content: space-between;
`;

const NotSamePaidTypePopup = (props) => {
    const { setShowPopup } = props;

    return (
        <Popup setShowPopup={setShowPopup}>
            <StyledHeadlineContainer><h3>Could not be added to the shopping cart. (Not the same payment type)</h3></StyledHeadlineContainer>
            <StyledBodyContainer>
                <StyledContentContainer>
                    <StyledButtonsContainer>
                        <Button inverted>
                            <Link to='/'>
                                Back to shop
                            </Link>
                        </Button>

                        <Button>
                            <Link to='/checkout'>Checkout<ButtonArrow/></Link>
                        </Button>
                    </StyledButtonsContainer>
                </StyledContentContainer>
            </StyledBodyContainer>
        </Popup>
    )
}

export default NotSamePaidTypePopup;
