import React from "react";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from '../../../icons/close.svg';

const StyledPopup = styled('div')`

`;

const StyledPopupBackdrop = styled('div')`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(33,33,33,0.5);
    z-index: 3;
`;

const StyledPopupBox = styled('div')`
    margin-top: 100px;
    background-color: ${props => props.theme.colors.white};
    position: relative;
    width: 80%;
    max-width: 950px;
    padding: 24px 24px 40px 24px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        padding: 30px 50px 50px 50px;
    }
`;

const StyledPopupContentWrapper = styled('div')`
`;

const StyledPopupCloseInput = styled('div')`
    position: absolute;
    top: 15px;
    right: 15px;

    &:hover {
        cursor: pointer;
        background-color: white;
    }
    
    svg {
        width: 20px;
        height: 20px;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        top: 20px;
        right: 20px;

        svg {
            width: 30px;
            height: 30px;
        }
    }
`;

function Popup(props) {
    const {children, setShowPopup} = props;

    return (
        <StyledPopup>
            <StyledPopupBackdrop>
                <StyledPopupBox>
                    <StyledPopupCloseInput onClick={() => { setShowPopup(false); }}><CloseIcon/></StyledPopupCloseInput>
                    <StyledPopupContentWrapper>
                        { children }
                    </StyledPopupContentWrapper>
                </StyledPopupBox>
            </StyledPopupBackdrop>
        </StyledPopup>
    );
}

export default Popup;
