import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation
} from 'react-router-dom';
import { List, Detail, Orders, Cart, Checkout, Profile, Success, PaymentFinished, PaymentCancelled, HeadlessContent } from './components/06_pages';
import { CustomContextProvider } from './components/00_utilities/context';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    return (
        <CustomContextProvider>
            <Router>
                <ScrollToTop />
                <Switch>
                    <Route path="/success" render={(props) => <Success {...props}/>}/>
                    <Route path="/payment-cancelled"><PaymentCancelled/></Route>
                    <Route path="/payment-finished"><PaymentFinished/></Route>
                    <Route path="/checkout"><Checkout/></Route>
                    <Route path="/orders"><Orders/></Route>
                    <Route path="/cart"><Cart/></Route>
                    <Route path="/profile"><Profile/></Route>
                    <Route path="/content/:key"><HeadlessContent/></Route>
                    <Route path="/detail/:id"><Detail/></Route>
                    <Route path="/detail"><Detail/></Route>
                    <Route path="/"><List/></Route>
                </Switch>
            </Router>
        </CustomContextProvider>
    );
}

export default App;
