import { themeSettings } from '../../07_themes/Default';

export default function componentVerticalPaddings() {
    const { breakpoints } = themeSettings;

    return `
        padding-top: 60px;
        padding-bottom: 60px;
    
        @media (min-width: ${breakpoints.xl.breakpoint}px) {
            padding-top: 100px;
            padding-bottom: 100px;
        }
    `
};
