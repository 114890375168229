import DefaultPage from '../../05_templates/Default';
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Context } from '../../00_utilities/context';
import ComponentContainer from '../../01_base/layout/ComponentContainer';
import Button from '../../02_atoms/Button';
import HomeLink from '../../02_atoms/HomeLink';
import ContentContainer from '../../01_base/layout/ContentContainer';
import { ReactComponent as ButtonArrow} from '../../../icons/arrow-right-long.svg';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import KeyVisual from '../../02_atoms/KeyVisual';

const StyledSuccessBox = styled('div')`
    .h1 {
        font-size: 32px;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            font-size: 40px;
        }
    }
`;

const StyledButtonsContainer = styled('div')`
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (min-width: ${props => props.theme.breakpoints.sm.breakpoint}px) {
        justify-content: flex-end;

        .button:first-child {
            margin-right: 32px;
        }
    }
`;

function Success(props) {
    const CHECKOUT_TYPE_SELF_PAID = 'self';
    const hasHistory = 'history' in props;
    const hasHistoryState = hasHistory && typeof props.history.location.state !== 'undefined';
    const hasOrderNumber = hasHistoryState && typeof props.history.location.state.orderNumber !== 'undefined';
    const hasSuccessImage = hasHistoryState && typeof props.history.location.state.successImage !== 'undefined' && props.history.location.state.successImage !== '';
    const orderNumber = hasOrderNumber ? props.history.location.state.orderNumber : null;
    const successImageMarkup = hasSuccessImage ? props.history.location.state.successImage : null;
    const hasCheckoutSelfPaidFlag = hasHistoryState && typeof props.history.location.state.checkoutSelfPaidOnSuccessPage !== 'undefined';
    const checkoutSelfPaidOnSuccessPage = hasCheckoutSelfPaidFlag ? props.history.location.state.checkoutSelfPaidOnSuccessPage : false;
    const context = React.useContext(Context);
    const [loadingCheckoutSelfPaid, setLoadingCheckoutSelfPaid] = React.useState(false);
    const [termsAccepted, setTermsAccepted] = React.useState(false);
    let termsCheckbox = null;

    const handleCheckoutResponse = (response) => {
        if (!('checkout_session_url' in response) || !(typeof response.checkout_session_url === 'string')) {
            alert(`Checkout Session URL missing in response for order ${response.order_number}. Please contact your admin with this message.`);
            context.updateCart({});

            return;
        }

        context.updateCart(response.cart_left);
        window.location.href = response.checkout_session_url;
    }

    const checkoutSelfPaid = () => {
        setLoadingCheckoutSelfPaid(true);

        urlFetch('/api/carts/checkout', {
            successCallback: handleCheckoutResponse,
        }, null, 'POST', null, {email: context.userEmail, checkout_type: CHECKOUT_TYPE_SELF_PAID}, context.token);
    }

    const updateTermsAccepted = (e) => {
        setTermsAccepted(e.target.checked);
    }

    const getCheckoutButton = () => {
        if (!termsAccepted) {
            return (
                <Button disabled>Accept terms first <ButtonArrow/></Button>
            )
        }

        return (
            <Button clickHandler={checkoutSelfPaid} loading={loadingCheckoutSelfPaid}>Checkout self paid products <ButtonArrow/></Button>
        )
    }

    if (checkoutSelfPaidOnSuccessPage) {
        termsCheckbox = <div style={{fontSize: '14px'}}><input id={'terms-checkbox'} type='checkbox' checked={termsAccepted} onChange={updateTermsAccepted}/><label htmlFor={'terms-checkbox'}>I accept the General <a href='https://brandshop.brainlab.com/content/terms'>Terms and Conditions</a>. Please note: You can revoke your consent at any time by emailing <a href='mailto:legal@brainlab.com'>legal@brainlab.com</a>. Your order will be processed according to our <a href='https://brandshop.brainlab.com/content/privacy-policy'>privacy policy</a>.</label></div>
    }

    return (
        <DefaultPage>
            {orderNumber === null && <Redirect to='/'/>}

            <KeyVisual imageMarkup={successImageMarkup} />

            <ComponentContainer>
                <ContentContainer>
                    <HomeLink/>
                    <div>
                        <p className='h1'>Order successfully completed!</p>
                    </div>
                    {
                        !checkoutSelfPaidOnSuccessPage &&
                        <StyledSuccessBox>
                            <span className='h4'>Thank you for your order <span className='order-number'>{orderNumber}</span>!</span>
                            <p>Your order has been received and is now being processed. You will receive a delivery
                                notification once fulfillment has been done. If you have questions regarding your order please
                                contact us by email to <a href='mailto:brandshop@brainlab.com'>brandshop@brainlab.com</a>. Include your order no. for faster processing.</p>
                            <p>An overview of your order has been sent by email to <b>{context.customer.email}</b>.</p>
                            <br/>
                            <StyledButtonsContainer>
                                <Button inverted>
                                    <Link to='/orders'>View order history</Link>
                                </Button>

                                <Button>
                                    <Link to='/'>Go to Shop <ButtonArrow/></Link>
                                </Button>
                            </StyledButtonsContainer>
                        </StyledSuccessBox>
                    }

                    {
                        checkoutSelfPaidOnSuccessPage &&
                        <StyledSuccessBox>
                            <span className='h4'>Thank you for your order <span className='order-number'>{orderNumber}</span>!</span>
                            <p>All ordered products <b>paid via internal cost center</b> successfully checked out. You will receive a delivery
                                notification once fulfillment has been done. If you have questions regarding your order please
                                contact us by email to <a href='mailto:brandshop@brainlab.com'>brandshop@brainlab.com</a>. Include your order no. for faster processing.</p>
                            <p>An overview of your order has been sent by email to <b>{context.customer.email}</b>.</p>
                            <br/>
                            <span className='h3'>Self paid products</span>
                            <p>We use Stripe for payment processing of self paid products. By clicking on “Checkout self paid products” you will be redirected to Stripe for payment.
                                After your payment has been confirmed, your order of self paid products is successfully placed.</p>
                            <br/>
                            {termsCheckbox}
                            <br/>
                            <StyledButtonsContainer>
                                { getCheckoutButton() }
                            </StyledButtonsContainer>
                        </StyledSuccessBox>
                    }

                </ContentContainer>
            </ComponentContainer>
        </DefaultPage>
    );
}

export default Success;
