import React from "react";
import styled from "styled-components";
import { Context } from '../../00_utilities/context';

const StyledFooter = styled('div')`
    ${props => props.css};
`;

function Footer() {
    const language = 'en';
    const context = React.useContext(Context);
    const { footerJson } = context;
    const translations = footerJson !== null && 'translations' in footerJson ? footerJson.translations : null;

    const footerMarkup = translations !== null && language in translations ? translations[language] : null;
    const footerCss = footerJson !== null && 'css' in footerJson ? footerJson.css : null;

    if (footerMarkup === null || footerCss === null) {
        return null;
    }

    return (
        <StyledFooter dangerouslySetInnerHTML={{ __html: footerMarkup }} css={footerCss}></StyledFooter>
    );
}

export default Footer;
