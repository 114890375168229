module.exports = (cookieName, cookieValue, expirationDays = 31) => {
    const date = new Date();
    date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
    let expirationString = `expires=${date.toUTCString()};`;

    // Negative values set a session cookie
    if (expirationDays < 0) {
        expirationString = '';
    }

    document.cookie = `${cookieName}=${cookieValue}; ${expirationString} path=/`;
};
