module.exports = (parameterKey) => {
    const url = new URL(window.location.href);

    if (!url.searchParams.has(parameterKey)) {
        return null;
    }

    const value = url.searchParams.get(parameterKey);

    url.searchParams.delete(parameterKey);
    window.history.replaceState({}, '', url.toString());

    return value;
};
