import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoutIcon} from '../../../icons/logout.svg';
import { ReactComponent as UserIcon} from '../../../icons/user.svg';
import { ReactComponent as CartIcon} from '../../../icons/cart.svg';
import Button from '../../02_atoms/Button';
import { Context } from '../../00_utilities/context';

const StyledSlideOutMenu = styled('div')`
    width: 100%;
    height: 100vh;
    background-color: ${props => props.theme.colors.pink};
    position: absolute;
    top: 50px;
    right: -100%;
    padding: 60px 20px;
    box-sizing: border-box;
    transition: right 500ms;

    &.active {
        right: 0;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        width: 375px;
        right: -375px;
    }
    
    ${props => {
        if (props.popupActive) {
            return `
                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(33,33,33,0.5);
                    content: '';
                }
            `
        }
    }}
`;

const StyledSlideOutClose = styled('div')`
    position: absolute;
    top: 5px;
    right: 10px;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: white;
        
    &:hover {
        cursor: pointer;
        color: ${props => props.theme.colors.pinkDark};
        background-color: white;
    }
`;

const StyledSlideOutMenuElementsContainer = styled('div')`
`;

const StyledSlideOutMenuElement = styled('div')`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid white;
    padding: 25px 0;
    margin-bottom: 10px;
    position: relative;
    
    &:first-child {
        padding-top: 0;
    }
    
    &:last-child {
        border-bottom: 0;
        margin-bottom: 30px;
    }
    
    &:hover {
        text-decoration: underline;
    }
`;

const StyledSlideOutMenuElementIconContainer = styled('div')`
    margin-right: 15px;
    
    svg {
        width: 30px;
        height: 30px;
        fill: ${props => props.theme.colors.white};
    }
`;

const StyledSlideOutMenuElementTextContainer = styled('div')`
    h4,
    p {
        margin: 0;
    }
`;

const StyledSlideOutMenuElementLink = styled(Link)`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`;

function SlideOutMenu (props, ref) {
    const { active, setActive, popupActive, bodyEventListener } = props;
    const context = React.useContext(Context);

    const hasFirstName = !!context.customer.firstName && context.customer.firstName !== '';
    const hasLastName = !!context.customer.lastName && context.customer.lastName !== '';
    const showUsername = hasFirstName && hasLastName;

    return (
        <StyledSlideOutMenu ref={ref} className={active ? 'active' : ''} popupActive={popupActive}>
            <StyledSlideOutClose onClick={() => { document.querySelector('body').removeEventListener('click', bodyEventListener); setActive(false); }}>✕</StyledSlideOutClose>

            <StyledSlideOutMenuElementsContainer>
                <StyledSlideOutMenuElement>
                    <StyledSlideOutMenuElementIconContainer>
                        <UserIcon/>
                    </StyledSlideOutMenuElementIconContainer>
                    <StyledSlideOutMenuElementTextContainer>
                        { showUsername && <h4>{ context.customer.firstName } { context.customer.lastName }</h4> }
                        { !showUsername && <h4>Profile</h4>}

                        <p>View and edit your profile</p>
                    </StyledSlideOutMenuElementTextContainer>

                    <StyledSlideOutMenuElementLink to='/profile'/>
                </StyledSlideOutMenuElement>

                <StyledSlideOutMenuElement>
                    <StyledSlideOutMenuElementIconContainer>
                        <CartIcon/>
                    </StyledSlideOutMenuElementIconContainer>
                    <StyledSlideOutMenuElementTextContainer>
                        <h4>Orders</h4>
                        <p>View your current and past orders</p>
                    </StyledSlideOutMenuElementTextContainer>

                    <StyledSlideOutMenuElementLink to='/orders'/>
                </StyledSlideOutMenuElement>
            </StyledSlideOutMenuElementsContainer>

            <Button inverted clickHandler={context.logout}>Logout <LogoutIcon/></Button>
        </StyledSlideOutMenu>
    )
}

export default React.forwardRef(SlideOutMenu);
