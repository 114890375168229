import React from "react";
import styled from "styled-components";
import Header from '../../04_organisms/Header/index';
import Footer from '../../04_organisms/Footer/index';
import { default as DefaultTheme } from '../../07_themes/Default';
import PageLoading from '../../03_molecules/PageLoading';

const StyledMain = styled('main')`
    position: relative;
    z-index: 2;
    flex-grow: 1;
`;

const StyledPageInnerWrapper = styled('div')`
    display: flex;
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
    margin-top: 50px;
`;

function DefaultPage(props) {
    const {children, popupActive, loading} = props;

    return (
        <DefaultTheme popupActive={popupActive}>
            <StyledPageInnerWrapper>
                <Header popupActive={popupActive}/>
                { loading && <StyledMain popupActive={popupActive}><PageLoading/></StyledMain> }
                { !loading && <StyledMain popupActive={popupActive}>{children}</StyledMain> }
                <Footer />
            </StyledPageInnerWrapper>
        </DefaultTheme>
    );
}

export default DefaultPage;
