import React from "react";
import { Context } from '../../00_utilities/context';
import Button from '../../02_atoms/Button';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import styled from 'styled-components';
import { ReactComponent as PencilIcon} from '../../../icons/edit.svg';
import FormInput from '../../02_atoms/FormInput';
import { ReactComponent as SaveIcon } from '../../../icons/save.svg';

const StyledInfoContent = styled('div')`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0 35px;
    
    .form-input-container {
        width: 100%;
        margin-bottom: 40px;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;

        .form-input-container {
            width: 50%;
        }
    }
`;

const StyledInfoEditElement = styled('div')`
    display: flex;
    justify-content: flex-start;
    padding: 0 35px;
`;

const CheckoutPaymentInformation = (props) => {
    const CHECKOUT_TYPE_FULL = 'full';
    const CHECKOUT_TYPE_SELF_PAID = 'self';
    const CHECKOUT_TYPE_INTERNALLY_PAID = 'internally';
    const [loading, setLoading] = React.useState(false);
    const [customerInState, setCustomerInState] = React.useState(null);
    const [isEditing, setIsEditing] = React.useState(false);
    const context = React.useContext(Context);
    const { customer } = context;
    const { checkoutType, termsAccepted, updateTermsAccepted } = props;

    React.useEffect(() => {
        setCustomerInState(customer);
    }, [customer]);

    const fieldChangeFlags = () => {
        const changes = {};
        const customerKeys = Object.keys(customer);

        for (let i = 0; i < customerKeys.length; i += 1) {
            const key = customerKeys[i];

            changes[key] = customer[key] !== customerInState[key];
        }

        return changes;
    }

    const fieldsHaveChanged = () => {
        const flags = fieldChangeFlags();

        return Object.values(flags).includes(true);
    }

    const changeHandler = (e) => {
        const {name, value} = e.currentTarget;
        updateCustomer(name, value);
    }

    const updateCustomer = (key, value) => {
        const newCustomerInstate = Object.assign({}, customerInState);
        newCustomerInstate[key] = value;
        setCustomerInState(newCustomerInstate);
    }

    const getValueByKey = (key) => {
        return (customerInState !== null && key in customerInState) ? customerInState[key] : '';
    }

    const editProfile = () => {
        setIsEditing(true);
    };

    const saveProfile = () => {
        if (!fieldsHaveChanged()) {
            setIsEditing(false);

            return;
        }

        setLoading(true);

        const body = {
            email: context.userEmail,
            data: customerInState,
        }

        urlFetch('/api/customers/update', { successCallback: (response) => { setLoading(false); setIsEditing(false); context.updateCustomer(response); } }, null, 'POST', null, body, context.token);
    };

    const profileEditingClickHandler = isEditing ? saveProfile : editProfile;

    const buttonText = isEditing ? 'Save' : 'Edit payment information';
    const icon = isEditing ? <SaveIcon/> : <PencilIcon/>;
    let editButton = null;
    let termsCheckbox = null;

    if (customerInState !== null && Object.keys(customerInState).length > 0) {
        editButton = <Button clickHandler={profileEditingClickHandler} loading={loading}>{buttonText} {icon}</Button>;
    }

    const internalCostCenterInputMarkup = <FormInput name={'internalCostCenter'} value={getValueByKey('internalCostCenter')} label='Internal cost center' changeHandler={changeHandler} isEditing={isEditing} options={customer.availableinternalCostCenter} isSelect internalCostCenter/>;
    const companyCodeMarkup = <FormInput name={'companyCode'} value={getValueByKey('companyCode')} label='Company Code' changeHandler={changeHandler} isEditing={isEditing} options={customer.availableCompanyCodes} isSelect companyCode/>;
    let internalCostCenterInput = null;
    let companyCodeInput = null;
    let stripeInfo = null;

    if (checkoutType === CHECKOUT_TYPE_INTERNALLY_PAID) {
        internalCostCenterInput = internalCostCenterInputMarkup;
        companyCodeInput = companyCodeMarkup;
    }

    if (checkoutType === CHECKOUT_TYPE_SELF_PAID) {
        stripeInfo = <div><p>We use Stripe for payment processing. By clicking on “Place order” you will be redirected to Stripe for payment. After your payment has been confirmed, your order is successfully placed.</p><br/></div>;
        termsCheckbox = <div style={{fontSize: '14px'}}><input id={'terms-checkbox'} type='checkbox' checked={termsAccepted} onChange={updateTermsAccepted}/><label htmlFor={'terms-checkbox'}>I accept the General <a href='https://brandshop.brainlab.com/content/terms'>Terms and Conditions</a>. Please note: You can revoke your consent at any time by emailing <a href='mailto:legal@brainlab.com'>legal@brainlab.com</a>. Your order will be processed according to our <a href='https://brandshop.brainlab.com/content/privacy-policy'>privacy policy</a>.</label></div>
    }

    if (checkoutType === CHECKOUT_TYPE_FULL) {
        internalCostCenterInput = internalCostCenterInputMarkup;
        companyCodeInput = companyCodeMarkup;
    }

    return (
        <div>
            <StyledInfoContent>
                {internalCostCenterInput}
                {stripeInfo}
                {termsCheckbox}
            </StyledInfoContent>
            <StyledInfoContent>
                {companyCodeInput}
            </StyledInfoContent>
            {checkoutType !== CHECKOUT_TYPE_SELF_PAID &&
                <StyledInfoEditElement>
                    { editButton }
                </StyledInfoEditElement>
            }
        </div>
    )
}

export default CheckoutPaymentInformation;
