import React from 'react';
import styled from 'styled-components';
import {Context} from "../../00_utilities/context";


const StyledProductInfoTable = styled('div')`
    width: 100%;
    max-width: 480px;
`;

const StyledSelfPaidText = styled('div')`
    font-size: 0.8rem;
    font-weight: ${props => props.theme.fontWeights.medium};
    color: ${props => props.theme.colors.pink};
`;

const StyledProductInfoPiece = styled('div')`
    ${props => {
        if (props.merged) {
            return '';        
        }
        
        if (!props.merged) {
            return `
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 30px;
                
                span:first-child {
                    font-weight: ${props.theme.fontWeights.medium};
                }
            `   
        }
    }}
`;

const StyledProductPrice = styled('div')`
    margin-top: 10px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        margin-top: 20px;
    }
`;

const StyledAdditionalContent = styled('div')`
    h4 {
        margin-top: 2rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        h4 {
            margin-top: 4rem;
        }
    }
`;


function ProductInfoTable (props) {
    const { product, merged = false } = props;
    const context = React.useContext(Context);

    const getProductInformationEntries = () => {
        let entries = [];
        const informationToRead = [
            {
                key: 'size',
                title: 'Size'
            },
            {
                key: 'colour',
                title: 'Colour'
            },
            {
                key: 'version',
                title: 'Version'
            },
            {
                key: 'material_nr',
                title: 'Material No.'
            },
        ];

        for (let i = 0; i < informationToRead.length; i++) {
            const informationPiece = informationToRead[i];

            if (!(informationPiece.key in product)) {
                continue;
            }

            if (product[informationPiece.key] === null || product[informationPiece.key] === '') {
                continue;
            }

            entries.push(
                <StyledProductInfoPiece key={`product-information-piece-${i}`} merged={merged}>
                    <span>{informationPiece.title}{merged && ': '}</span>
                    <span>{product[informationPiece.key]}</span>
                </StyledProductInfoPiece>
            )
        }

        return entries;
    }

    const getAdditionalContent = () => {
        if (merged) {
            return null;
        }

        if (!product.hasOwnProperty('additional_content') || product['additional_content'] === null) {
            return null;
        }

        return <StyledAdditionalContent dangerouslySetInnerHTML={{ __html: product['additional_content'] }}/>
    }

    const priceInfoText = product.is_self_paid ? <>(incl. VAT. <a href={context.faqUrl} target='_blank' rel="noreferrer">More information.</a>)</> : '(per packaging unit)';

    return (
        <StyledProductInfoTable>
            { getProductInformationEntries() }
            { product.is_self_paid &&
                <>
                    <StyledSelfPaidText>Self paid</StyledSelfPaidText>
                    {merged &&
                        <StyledProductPrice>
                            <strong style={{marginBottom: product.is_self_paid ? '0.33rem' : '1.33rem'}}>{product.price} €</strong>
                            <br />
                            <span>{priceInfoText}</span>
                        </StyledProductPrice>
                    }
                </>
            }

            { getAdditionalContent() }
        </StyledProductInfoTable>
    )
}

export default ProductInfoTable;
