import styled from "styled-components";

const ProductCardsContainer = styled('div')`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -30px;

    > .product-card {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 30px;
        box-sizing: border-box;

        @media (min-width: ${props => props.theme.breakpoints.md.breakpoint}px) {
            width: 50%;
        }

        @media (min-width: ${props => props.theme.breakpoints.xxl.breakpoint}px) {
            width: 33.3333%;
        }
    }

    @media (min-width: ${props => props.theme.breakpoints.md.breakpoint}px) {
        flex-direction: row;
    }
`;

export default ProductCardsContainer;
