import styled from "styled-components";
import componentSidePaddings from '../../../00_utilities/mixins/componentSidePaddings';
import componentVerticalPaddings from '../../../00_utilities/mixins/componentVerticalPaddings';

const ComponentContainer = styled('div')`
    position: relative;
    ${componentSidePaddings()};
    ${componentVerticalPaddings()};
`;

export default ComponentContainer;
