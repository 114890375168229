import cssLock from './cssLock';
import { themeSettings } from '../../07_themes/Default';

export default function overrideComponentSidePaddings() {
    const { breakpoints } = themeSettings;

    return `
        ${cssLock('margin-left', breakpoints.sm.breakpoint, breakpoints.xxl.breakpoint, -15, -240)};
        ${cssLock('margin-right', breakpoints.sm.breakpoint, breakpoints.xxl.breakpoint, -15, -240)};
    `
};
