import React from "react";
import styled from "styled-components";

const StyledLoadingIcon = styled('div')`
    width: 30px;
    height: 30px;
    display: block;
    position: relative;
    margin: 0 auto;
    border: 3px solid;
    border-color: ${props => props.theme.colors.pink};
    border-top-color: ${props => props.theme.colors.whiteSmoke};
    border-radius: 50%;
    animation: spin 600ms linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0);
        }

        100% {
            transform: rotate(360deg);
        }
    }
`;

function LoadingIcon() {
    return (
        <StyledLoadingIcon className='loading-icon'/>
    );
}

export default LoadingIcon;
