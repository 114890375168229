import DefaultPage from '../../05_templates/Default';
import React from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Context } from '../../00_utilities/context';
import ComponentContainer from '../../01_base/layout/ComponentContainer';
import HomeLink from '../../02_atoms/HomeLink';
import ContentContainer from '../../01_base/layout/ContentContainer';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import stripUrlParameter from '../../00_utilities/functions/stripUrlParameter';
import KeyVisual from '../../02_atoms/KeyVisual';

const StyledTextBox = styled('div')`
    .h1 {
        font-size: 32px;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            font-size: 40px;
        }
    }
`;

function PaymentCancelled() {
    const [loading, setLoading] = React.useState(true);
    const [successImageMarkup, setSuccessImageMarkup] = React.useState(null);
    const [orderNumber, setOrderNumber] = React.useState(null);
    const context = React.useContext(Context);

    React.useEffect(() => {
        const orderNumber = stripUrlParameter('order_number');
        setOrderNumber(orderNumber);
        urlFetch('/api/orders/cancel-payment', { successCallback, serverErrorCallback }, null, 'POST', null, { email: context.userEmail, order_number: orderNumber }, context.token);
    }, []); // eslint-disable-line

    const successCallback = (response) => {
        setSuccessImageMarkup(response.image);
        setLoading(false);
    }

    const serverErrorCallback = (response) => {
        setLoading(false);
    }

    return (
        <DefaultPage loading={loading}>
            {orderNumber === null && <Redirect to='/'/>}

            <KeyVisual imageMarkup={successImageMarkup} />

            <ComponentContainer>
                <ContentContainer>
                    <HomeLink/>

                    <StyledTextBox>
                        <div>
                            <p className='h1'>Payment cancelled</p>
                        </div>
                        Your payment was cancelled. Please start a new checkout process by adding products to your cart and finish the payment.
                    </StyledTextBox>
                </ContentContainer>
            </ComponentContainer>
        </DefaultPage>
    );
}

export default PaymentCancelled;
