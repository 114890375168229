import React from 'react';
import styled from 'styled-components';
import FormContainer from '../../01_base/layout/FormContainer';
import FormInput from '../../02_atoms/FormInput';
import FormInputColumn from '../../01_base/layout/FormInputColumn';
import { Context } from '../../00_utilities/context';

const StyledDeliveryAddress = styled('div')`
    margin-top: 0.5rem;
    
    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        .form-input-column:nth-child(2) {
            .form-input-container:nth-child(1) {
                padding-right: 15px;
                box-sizing: border-box;
            }
            .form-input-container:nth-child(2) {
                padding-left: 15px;
                box-sizing: border-box;
            }
            .form-input-container:nth-child(4) {
                height: 142px;

                textarea {
                    height: 142px;
                }
            }
        }
    }
`;

function DeliveryAddress (props) {
    const {getValueByKey, changeHandler, isEditing} = props;
    const context = React.useContext(Context);
    const { customer } = context;

    return (
        <StyledDeliveryAddress>
            <FormContainer>
                <FormInputColumn>
                    <FormInput name={'firstName'} value={getValueByKey('firstName')} label='First name' changeHandler={changeHandler} isEditing={isEditing} componentWidth='100%'/>
                    <FormInput name={'lastName'} value={getValueByKey('lastName')} label='Last name' changeHandler={changeHandler} isEditing={isEditing} componentWidth='100%'/>
                </FormInputColumn>
                <FormInputColumn>
                    <FormInput
                        name={'officeLocation'}
                        value={getValueByKey('officeLocation')}
                        label='Office location'
                        changeHandler={changeHandler}
                        isEditing={isEditing}
                        options={customer.availableOfficeLocations}
                        isSelect
                        componentWidth='100%'
                    />
                </FormInputColumn>
                <FormInputColumn>
                    <FormInput name={'streetAndNumber'} value={getValueByKey('streetAndNumber')} label='Street / No.' changeHandler={changeHandler} isEditing={isEditing} componentWidth='100%'/>
                    <FormInput name={'zipcode'} value={getValueByKey('zipcode')} label='Zipcode' changeHandler={changeHandler} isEditing={isEditing} componentWidth='30%'/>
                    <FormInput name={'city'} value={getValueByKey('city')} label='City' changeHandler={changeHandler} isEditing={isEditing} componentWidth='70%'/>
                    <FormInput name={'state'} value={getValueByKey('state')} label='State' changeHandler={changeHandler} isEditing={isEditing} componentWidth='100%'/>
                    <FormInput name={'country'} value={getValueByKey('country')} label='Country' changeHandler={changeHandler} isCountrySelect isEditing={isEditing} componentWidth='100%'/>
                    <FormInput name={'optionalDeliveryInstructions'} value={getValueByKey('optionalDeliveryInstructions')} label='Optional invoice instructions' changeHandler={changeHandler} isTextArea isEditing={isEditing} componentWidth='100%'/>
                </FormInputColumn>
            </FormContainer>
        </StyledDeliveryAddress>
    )
}

export default DeliveryAddress;
