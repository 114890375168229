import React from 'react';
import styled from 'styled-components';
import QuantitySelect from '../../02_atoms/QuantitySelect';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import { ReactComponent as DeleteIcon } from '../../../icons/trash.svg';
import { Context } from '../../00_utilities/context';
import updateCart from '../../00_utilities/connectivity/updateCart';
import LoadingIcon from '../../02_atoms/LoadingIcon';
import SizeSelect from "../../02_atoms/SizeSelect";

const StyledCartElement = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    border-bottom: 1px solid grey;
    
    .cart-element__image-container {
        width: 100%;
        height: 200px;
        margin-bottom: 20px;
        position: relative;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            width: 33.3333%;
            margin-right: 40px;
            margin-bottom: 0;
        }
    }
    
    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
    }
`;

const StyledImageContainer = styled('div')`
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

const StyledAmountContainer = styled('div')`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    
    select {
        width: 175px;
        margin-right: 20px;
    }

    .cart-element__delete-element {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 18px;
            height: 18px;
            fill: ${props => props.theme.colors.pink};
        }
        
        .loading-icon {
            width: 18px;
            height: 18px;
        }
        
        &:hover {
            cursor: pointer;
        }
    }
`;

const StyledSelfPaidText = styled('div')`
    font-size: 0.8rem;
    font-weight: ${props => props.theme.fontWeights.medium};
    color: ${props => props.theme.colors.pink};
    margin-bottom: 1.33rem;
`;

const RemoveButton = (props) => {
    const { productId } = props;
    const context = React.useContext(Context);
    const [loadingRemoval, setLoadingRemoval] = React.useState(false);

    const removeFromCartObject = (productId) => {
        const newCart = Object.assign({}, context.cart);
        delete newCart.items[productId];

        return newCart;
    }

    const removeFromCart = () => {
        const data = removeFromCartObject(productId);

        setLoadingRemoval(true);
        urlFetch('/api/carts/update', { successCallback: (response) => { setLoadingRemoval(false); context.updateCart(response); } }, null, 'POST', null, { email: context.userEmail, data }, context.token);
    }

    return (
        <div className='cart-element__delete-element' onClick={removeFromCart}>
            { loadingRemoval ? <LoadingIcon/> : <DeleteIcon/>}
        </div>
    )
}


function CartElement (props) {
    const { cartItem, product } = props;
    const context = React.useContext(Context);
    const priceInfoText = product.is_self_paid ? <>(incl. VAT. <a href={context.faqUrl} target='_blank' rel="noreferrer">More information.</a>)</> : '(per packaging unit)';

    return (
        <StyledCartElement className='cart-element'>
            <div className='cart-element__image-container'>
                <StyledImageContainer dangerouslySetInnerHTML={{ __html: product.image.list }}></StyledImageContainer>
            </div>

            <div>
                <h3>{product.title}</h3>
                <h4 style={{marginBottom: product.is_self_paid ? '0.33rem' : '1.33rem'}}>{product.price} € {priceInfoText}</h4>
                { product.is_self_paid ? <StyledSelfPaidText>Self paid</StyledSelfPaidText> : '' }

                { product.sizes.length > 0 &&
                    <StyledAmountContainer>
                        <SizeSelect value={cartItem.size} sizes={product.sizes} changeHandler={(e) => { updateCart(product, cartItem.count, e.currentTarget.value, context) }}/>
                    </StyledAmountContainer>
                }
                {
                    cartItem &&
                    <StyledAmountContainer>
                        <QuantitySelect value={cartItem.count} changeHandler={(e) => { updateCart(product, e.currentTarget.value, cartItem.count, context) }}/>
                        <RemoveButton productId={product.id}/>
                    </StyledAmountContainer>
                }
            </div>
        </StyledCartElement>
    )
}

export default CartElement;
