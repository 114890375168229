import DefaultPage from '../../05_templates/Default';
import React from 'react';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import { Context } from '../../00_utilities/context';
import HomeLink from '../../02_atoms/HomeLink';
import OrderElement from '../../03_molecules/OrderElement';
import ComponentContainer from '../../01_base/layout/ComponentContainer';
import ContentContainer from '../../01_base/layout/ContentContainer';
import LoadMore from '../../03_molecules/LoadMore';
import styled from 'styled-components';
import NoOrdersContainer from '../../03_molecules/NoOrdersContainer';

const StyledOrderElementsContainer = styled('div')`
    margin-bottom: 80px;
`;

function Orders() {
    const [orders, setOrders] = React.useState([]);
    const context = React.useContext(Context);
    const [offset, setOffset] = React.useState(0);
    const [limit, setLimit] = React.useState(0);
    const [loadMore, setLoadMore] = React.useState(false);
    const [isLoadingMore, setIsLoadingMore] = React.useState(false);
    const [isLoadingInitially, setIsLoadingInitially] = React.useState(false);

    React.useEffect(() => {
        setIsLoadingInitially(true);
        urlFetch('/api/orders', { successCallback: ordersSuccessCallback }, null, 'POST', null, { email: context.userEmail, offset }, context.token);
    }, []); // eslint-disable-line

    const setLoadMoreStates = (response) => {
        setOffset(response.offset);
        setLimit(response.limit);
        setLoadMore(response.load_more);
    }

    const ordersSuccessCallback = (response) => {
        setOrders(response.elements);
        setIsLoadingInitially(false);
        setLoadMoreStates(response);
    }

    const getOrderElements = () => {
        const elements = [];

        for (let i = 0; i < orders.length; i += 1) {
           elements.push(<OrderElement order={orders[i]} initiallyActive={i === 0} key={`order-${orders[i].id}`}/>)
        }

        return <StyledOrderElementsContainer>{elements}</StyledOrderElementsContainer>
    }

    const setOrdersAfterLoadMore = (response) => {
        const newOrders = [].concat(orders, response.elements);
        setOrders(newOrders);
        setIsLoadingMore(false);

        setLoadMoreStates(response);
    }

    const loadMoreClickHandler = () => {
        setIsLoadingMore(true);
        const newOffset = parseInt(limit, 10) + parseInt(offset, 10);

        urlFetch('/api/orders', { successCallback: setOrdersAfterLoadMore }, null, 'POST', null, { email: context.userEmail, offset: newOffset }, context.token);
    }

    return (
        <DefaultPage loading={isLoadingInitially}>
            <ComponentContainer>
                <ContentContainer>
                    <HomeLink/>

                    <div>
                        <h1>Your order history</h1>
                        <hr/>
                        <br/>

                        { orders.length > 0 ? getOrderElements() : <NoOrdersContainer/> }
                        { orders.length > 0 && loadMore && <LoadMore clickHandler={loadMoreClickHandler} loading={isLoadingMore}/>}
                    </div>
                </ContentContainer>
            </ComponentContainer>
        </DefaultPage>
    );
}

export default Orders;
