import DefaultPage from '../../05_templates/Default';
import React from 'react';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Context } from '../../00_utilities/context';
import Button from '../../02_atoms/Button';
import ComponentContainer from '../../01_base/layout/ComponentContainer';
import ContentContainer from '../../01_base/layout/ContentContainer';
import ProductCard from '../../03_molecules/ProductCard';
import ProductCardsContainer from '../../01_base/layout/ProductCardsContainer';
import LoadMore from '../../03_molecules/LoadMore';
import { ReactComponent as FilterIcon } from '../../../icons/filter.svg';
import { ReactComponent as ButtonArrow } from '../../../icons/arrow-right-long.svg';
import LoadingIcon from '../../02_atoms/LoadingIcon';
import OrderSlider from '../../03_molecules/OrderSlider';
import FirstTimeHerePopup from '../../03_molecules/FirstTimeHerePopup';


const StyledProductCardsContainer = styled(ProductCardsContainer)`
    .product-card__inner-wrapper {
        background-color: ${props => props.theme.colors.whiteSmoke};
    }
    
    margin-bottom: 60px;
`;

const StyledIntroBoxes = styled('div')`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
    }
`;

const StyledUserIntroBox = styled('div')`
    margin-bottom: 70px;
    box-sizing: border-box;
    width: 100%;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        width: 50%;
        margin-right: 32px;
        margin-bottom: 0;
    }
    
    p {
        margin: 0;
    }
    
    p:not(:last-child) {
        margin-bottom: 5px;
    }
`;

const StyledOrdersIntroBox = styled('div')`
    margin: 0;
    box-sizing: border-box;
    background-color: ${props => props.theme.colors.whiteSmoke};
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    min-height: 170px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        width: 50%;
        padding: 32px;
        min-height: 200px;
    }
    
    h3 {
        margin-bottom: 0;
    }
    
    p {
        margin: 0;
    }
`;

const StyledOrdersIntroBoxBody = styled('div')`

`;

const StyledOrdersIntroBoxOrdersContainer = styled('div')`
    border-top: 1px solid ${props => props.theme.colors.darkGrey};
    border-bottom: 1px solid ${props => props.theme.colors.darkGrey};
    padding: 20px 0;
    margin: 10px 0 20px 0;
`;

const StyledOrdersIntroBoxButtonContainer = styled('div')`
    margin-top: auto;
`;

const StyledCategoryFilterMobile = styled('div')`
    display: flex;
    flex-direction: row;
    font-size: 14px;
    padding: 15px 0;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        display: none;
    }
`;

const StyledCategoryFilterMobileSelectContainer = styled('div')`
    margin-right: 10px;
    position: relative;

    &::after {
        position: absolute;
        top: 2px;
        right: -12px;
        height: 20px;
        min-width: 1px;
        content: '';
        border-right: 1px solid ${props => props.theme.colors.black};
    }
`;

const StyledCategoryFilterMobileClicker = styled('div')`
    color: ${props => props.theme.colors.pink};
    font-weight: ${props => props.active ? props.theme.fontWeights.medium : props.theme.fontWeights.normal};
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg {
        width: 14px;
        height: 14px;
        fill: ${props => props.theme.colors.pink};
        margin-right: 7px;
    }
    
    &:hover {
        cursor: pointer;
        font-weight: ${props => props.theme.fontWeights.medium};
    }
`;

const StyledCategoryFilterMobileSelect = styled('select')`
    position: absolute;
    left: 0;
    opacity: 0;
    width:  100%;
    font-size: 14px;
    outline: 0;
    border: 0;
    appearance: none;
    color: ${props => props.theme.colors.pink};
    font-weight: ${props => props.theme.fontWeights.medium};
    
    &:hover {
        cursor: pointer;
        font-weight: ${props => props.theme.fontWeights.medium};
    }
`;

const StyledCategoryFilterMobileAll = styled('span')`
    margin-left: 12px;
    font-weight: ${props => props.active ? props.theme.fontWeights.medium : props.theme.fontWeights.normal};
    
    &:hover {
        cursor: pointer;
        font-weight: ${props => props.theme.fontWeights.medium};
    }
`;

const StyledCategoryFilterDesktop = styled('div')`
    display: none;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        display: block;
    }
`;

const StyledCategoryFilterElement = styled('p')`
    font-size: 14px;
    font-weight: ${props => props.active ? props.theme.fontWeights.medium : props.theme.fontWeights.normal};
    
    &:hover {
        cursor: pointer;
        font-weight: ${props => props.theme.fontWeights.medium};
    }
`;

const StyledCompanyFilter = styled('div')`
    margin-bottom: 55px;
    border-bottom: 1px solid ${props => props.theme.colors.black};

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        margin-bottom: 80px;
    }
`;

const StyledCompanyFilterElement = styled('div')`
    padding: 15px 80px 15px 0;
    position: relative;
    display: inline-block;

    ${props => {
        if (props.active) {
            return `
                    font-weight: ${props.theme.fontWeights.medium};
                    
                    &::after {
                        content: '';
                        width: 100%;
                        height: 8px;
                        position: absolute;
                        bottom: -4px;
                        left: 0;
                        background-color: ${props.theme.colors.pink};
                    }
            `;
        }
    }};
`;

const StyledListBody = styled('div')`
    display: flex;
    flex-direction: column;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
    }
`;

const StyledListCategoriesContainer = styled('div')`
    width: 100%;
    position: sticky;
    top: 50px;
    z-index: 1;
    background: white;
    
    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        width: 17%;
    }
`;

const StyledListProductsContainer = styled('div')`
    width: 100%;
    margin-top: 10px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        width: 83%;
        margin-top: 0;
    }
`;

function List() {
    const [products, setProducts] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [currentCategory, setCurrentCategory] = React.useState(null);
    const [offset, setOffset] = React.useState(0);
    const [limit, setLimit] = React.useState(0);
    const [loadMore, setLoadMore] = React.useState(false);
    const [isLoadingMore, setIsLoadingMore] = React.useState(false);
    const [isLoadingOrders, setIsLoadingOrders] = React.useState(true);
    const [showPopup, setShowPopup] = React.useState(true);
    const [orders, setOrders] = React.useState([]);
    const context = React.useContext(Context);
    const maxOrders = 3;

    const setLoadMoreStates = (response) => {
        setOffset(response.offset);
        setLimit(response.limit);
        setLoadMore(response.load_more);
    }

    const productSuccessCallback = (response) => {
        setProducts(response.elements);
        setLoadMoreStates(response);
    }

    const productLoadMoreSuccessCallback = (response) => {
        const newProducts = [].concat(products, response.elements);
        setProducts(newProducts);
        setIsLoadingMore(false);

        setLoadMoreStates(response);
    }

    const ordersSuccessCallback = (response) => {
        setOrders(response.elements);
        setIsLoadingOrders(false);
    }

    React.useEffect(() => {
        urlFetch('/api/products', { successCallback: productSuccessCallback }, null, 'POST', { offset }, { email: context.userEmail }, context.token);
        urlFetch('/api/products/categories', { successCallback: setCategories }, null, 'POST', null, { email: context.userEmail }, context.token);
        urlFetch('/api/orders', { successCallback: ordersSuccessCallback }, null, 'POST', null, { email: context.userEmail, offset: 0, limit: maxOrders, for_slider: true }, context.token);
    }, []); // eslint-disable-line

    const filterProducts = (categoryId = null) => {
        const queryParameters = categoryId !== null ? { category: categoryId } : null;

        urlFetch('/api/products', { successCallback: productSuccessCallback }, null, 'POST', queryParameters, { email: context.userEmail }, context.token);
    }

    const loadMoreClickHandler = () => {
        setIsLoadingMore(true);
        const newOffset = parseInt(limit, 10) + parseInt(offset, 10);
        const queryParameters = { offset: newOffset };

        if (currentCategory !== null) {
            queryParameters.category = currentCategory.id;
        }

        urlFetch('/api/products', { successCallback: productLoadMoreSuccessCallback }, null, 'POST', queryParameters, { email: context.userEmail }, context.token);
    };

    const getProductList = () => {
        const productDivs = [];

        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            productDivs.push(<ProductCard key={`product-${i}`} product={product}/>)
        }

        return <StyledProductCardsContainer>{productDivs}</StyledProductCardsContainer>;
    }

    const selectChangeHandler = (e) => {
        for (let i = 0; i < categories.length; i++) {
            const category = categories[i];

            if (category.id === parseInt(e.currentTarget.value)) {
                setCurrentCategory(category);
                filterProducts(category.id);

                return;
            }
        }
    }

    const getCategoryFilters = () => {
        const filterElementsMobile = [];
        for (let i = 0; i < categories.length; i++) {
            const category = categories[i];

            filterElementsMobile.push(
                <option key={`product-filter-element-mobile-${i}`} value={category.id}>{category.title}</option>
            )
        }

        const selectValue = currentCategory === null ? '' : currentCategory.id;

        const filterMobileSelect = <StyledCategoryFilterMobileSelect name='product-filter-mobile-select' value={selectValue} onChange={selectChangeHandler}>{filterElementsMobile}</StyledCategoryFilterMobileSelect>;
        const filterMobileTitle = currentCategory === null ? 'Category' : currentCategory.title;
        const filterMobileClicker = <StyledCategoryFilterMobileClicker active={currentCategory !== null}><FilterIcon/> {filterMobileTitle}</StyledCategoryFilterMobileClicker>;

        const filterMobileSelectContainer = <StyledCategoryFilterMobileSelectContainer>{filterMobileSelect}{filterMobileClicker}</StyledCategoryFilterMobileSelectContainer>;
        const filterMobileAll = <StyledCategoryFilterMobileAll active={currentCategory === null} key='product-filter-mobile-desktop-all' onClick={() => { filterProducts(); setCurrentCategory(null); }}>Show all</StyledCategoryFilterMobileAll>;

        const mobileFilter = <StyledCategoryFilterMobile>{filterMobileSelectContainer}{filterMobileAll}</StyledCategoryFilterMobile>;

        const filterElementsDesktop = [
            <StyledCategoryFilterElement active={currentCategory === null} key='product-filter-element-desktop-all' onClick={() => { filterProducts(); setCurrentCategory(null); }}>Show all</StyledCategoryFilterElement>
        ];

        for (let i = 0; i < categories.length; i++) {
            const category = categories[i];

            filterElementsDesktop.push(
                <StyledCategoryFilterElement active={currentCategory !== null && currentCategory.id === category.id} key={`product-filter-element-desktop-${i}`} onClick={() => { filterProducts(category.id); setCurrentCategory(category); }}>{category.title}</StyledCategoryFilterElement>
            )
        }

        const desktopFilter = <StyledCategoryFilterDesktop>{filterElementsDesktop}</StyledCategoryFilterDesktop>;

        return <>{mobileFilter} {desktopFilter}</>;
    }

    const getOrdersIntroBoxBody = () => {
        let content = '';

        if (isLoadingOrders) {
            content = <LoadingIcon/>;
        }

        if (!isLoadingOrders && orders.length === 0) {
            content = <p>You have no open orders</p>;
        }

        if (!isLoadingOrders && orders.length > 0) {
            content = <StyledOrdersIntroBoxOrdersContainer>
                <OrderSlider maxOrders={maxOrders} orders={orders}/>
            </StyledOrdersIntroBoxOrdersContainer>;
        }

        return <StyledOrdersIntroBoxBody>{content}</StyledOrdersIntroBoxBody>;
    }

    const loading = categories.length === 0 || products.length === 0;

    return (
        <DefaultPage loading={loading} popupActive={!context.customerIsComplete && showPopup}>
            <ComponentContainer>
                { (!context.customerIsComplete && showPopup) && <FirstTimeHerePopup setShowPopup={setShowPopup}/> }

                <ContentContainer>
                    <div>
                        <h2>Hello {context.customer.firstName ? context.customer.firstName : ''}</h2>
                    </div>

                    <StyledIntroBoxes>
                        <StyledUserIntroBox>
                            <p>Welcome to the Brainlab Brand Shop. This is your one-stop shop for official Brainlab
                                branded marketing materials.</p>
                            <p>Browse through all available products or choose a category if you already know what you
                                are looking for. To get more information about a product, click on the "More info"
                                buttons. To order products, choose the desired amount and click on “Add to cart” on the
                                information page of a product.</p>
                            <p>Do you have questions? Get in contact with <a
                                href='mailto:brandshop@brainlab.com'>brandshop@brainlab.com</a>.</p>
                            <p></p>
                            <strong>A quick note about our bike collection: </strong>
                            <p>All products are showcased at our Point-of-Purchase display and can be tried on at the
                                following locations: Munich Gym (Germany) and Westchester (USA).</p>
                            <p>Pick up is currently only possible at these two locations!</p>
                            <p>Once you have placed your order, you can collect your items from one of the two
                                locations.</p>
                            <p>The pick-up times in the Munich gym are Monday to Friday from 13:00-13:30.</p>
                        </StyledUserIntroBox>

                        <StyledOrdersIntroBox>
                            <h3>Your Orders</h3>

                            {getOrdersIntroBoxBody()}

                            <StyledOrdersIntroBoxButtonContainer>
                                <Button>
                                <Link to={'/orders'}>View order history <ButtonArrow/></Link>
                                </Button>
                            </StyledOrdersIntroBoxButtonContainer>
                        </StyledOrdersIntroBox>
                    </StyledIntroBoxes>
                    <div>
                        <h4>Branded marketing materials</h4>

                        <StyledCompanyFilter>
                            <StyledCompanyFilterElement active>Brainlab</StyledCompanyFilterElement>
                        </StyledCompanyFilter>

                        <StyledListBody>
                            <StyledListCategoriesContainer>
                                { getCategoryFilters() }
                            </StyledListCategoriesContainer>

                            <StyledListProductsContainer>
                                { getProductList() }
                            </StyledListProductsContainer>
                        </StyledListBody>

                        { loadMore && <LoadMore clickHandler={loadMoreClickHandler} loading={isLoadingMore}/> }
                    </div>
                </ContentContainer>
            </ComponentContainer>
        </DefaultPage>
    );
}

export default List;
