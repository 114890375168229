import React from "react";
import { Context } from '../../00_utilities/context';
import Button from '../../02_atoms/Button';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import styled from 'styled-components';
import { ReactComponent as PencilIcon} from '../../../icons/edit.svg';
import { ReactComponent as SaveIcon} from '../../../icons/save.svg';
import FormInput from '../../02_atoms/FormInput';
import DeliveryAddress from '../DeliveryAddress';
import FormContainer from '../../01_base/layout/FormContainer';

const StyledTopInfoH1 = styled('h1')`
    float: left;
    margin-bottom: unset;
`;

const StyledTopInfoEditElement = styled('div')`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
`;


const StyledInfoEditElement = styled('div')`
    display: flex;
    justify-content: flex-end;
    background-color: ${props => props.theme.colors.whiteSmoke};
    border-bottom: 1px solid #434549;
    padding: 32px;
    margin-top: 50px;
`;

const CustomerInformation = ({usernameElement, emailElement}) => {
    const [loading, setLoading] = React.useState(false);
    const [customerInState, setCustomerInState] = React.useState(null);
    const [isEditingProfile, setIsEditingProfile] = React.useState(false);
    const context = React.useContext(Context);
    const { customer } = context;

    React.useEffect(() => {
        setCustomerInState(customer);
    }, [customer]);

    const fieldChangeFlags = () => {
        const changes = {};
        const customerKeys = Object.keys(customer);

        for (let i = 0; i < customerKeys.length; i += 1) {
            const key = customerKeys[i];

            changes[key] = customer[key] !== customerInState[key];
        }

        return changes;
    }

    const fieldsHaveChanged = () => {
        const flags = fieldChangeFlags();

        return Object.values(flags).includes(true);
    }

    const changeHandler = (e) => {
        const {name, value} = e.currentTarget;
        updateCustomer(name, value);
    }

    const updateCustomer = (key, value) => {
        const newCustomerInstate = Object.assign({}, customerInState);
        newCustomerInstate[key] = value;
        setCustomerInState(newCustomerInstate);
    }

    const editProfile = () => {
        setIsEditingProfile(true);
    };

    const saveProfile = () => {
        if (!fieldsHaveChanged()) {
            setIsEditingProfile(false);

            return;
        }

        setLoading(true);

        const body = {
            email: context.userEmail,
            data: customerInState,
        }

        urlFetch('/api/customers/update', { successCallback: (response) => { setLoading(false); setIsEditingProfile(false); context.updateCustomer(response); } }, null, 'POST', null, body, context.token);
    };

    const profileEditingClickHandler = isEditingProfile ? saveProfile : editProfile;
    const buttonText = isEditingProfile ? 'Save' : 'Edit profile';

    let editButton = null;
    const icon = isEditingProfile ? <SaveIcon/> : <PencilIcon/>;

    if (customerInState !== null && Object.keys(customerInState).length > 0) {
        editButton = <Button clickHandler={profileEditingClickHandler} loading={loading}>{buttonText} {icon}</Button>;
    }

    const getValueByKey = (key) => {
        return (customerInState !== null && key in customerInState && customerInState[key] !== null) ? customerInState[key] : '';
    }

    return (
        <div>
            <StyledTopInfoH1>Your profile</StyledTopInfoH1>

            <StyledTopInfoEditElement>
                { editButton }
            </StyledTopInfoEditElement>

            <hr/>
            <br/>
            <div>Currently logged in as: {usernameElement} {emailElement}</div>
            <div>Your mail address can’t be changed here, as you’ve logged in with your Brainlab credentials, that are managed centrally.</div>
            <br/>
            <h2>1 Invoice</h2>
            <div>Please enter your private address in this section. Your address will be used for the invoice that will be provided within the checkout process.</div>
            <DeliveryAddress isEditing={isEditingProfile} getValueByKey={getValueByKey} changeHandler={changeHandler}/>

            {customer.hasMarketingMaterialCollection &&

                <>
                    <br/>
                    <h2>2 Payment</h2>
                    <FormContainer>
                    <span className='cost-center-info'>Only needed for special branded marketing materials (stationery) that will be charged internally.</span>
                    <FormInput componentWidth="100%" name={'internalCostCenter'} value={getValueByKey('internalCostCenter')} label='Internal cost center' changeHandler={changeHandler} isEditing={isEditingProfile} options={customer.availableinternalCostCenter} isSelect internalCostCenter/>
                    <FormInput componentWidth="100%" name={'companyCode'} value={getValueByKey('companyCode')} label='Company Code' changeHandler={changeHandler} isEditing={isEditingProfile} options={customer.availableCompanyCodes} isSelect companyCode/>
                    </FormContainer>
                </>
            }

            <StyledInfoEditElement>
                { editButton }
            </StyledInfoEditElement>

            <br/>
            <br/>
        </div>
    )
}

export default CustomerInformation;
