import React from "react";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ImageWrapper from '../../01_base/layout/ImageWrapper';
import Popup from '../../02_atoms/Popup';
import Button from '../../02_atoms/Button';
import { ReactComponent as ButtonArrow } from '../../../icons/arrow-right-long.svg';
import { Context } from '../../00_utilities/context';

const StyledHeadlineContainer = styled('div')`
    width: 100%;
    margin-bottom: 10px;
    font-size: 24px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        margin-bottom: 30px;
        font-size: 26px;
    }
`;

const StyledBodyContainer = styled('div')`
    display: flex;
    flex-direction: column;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
    }
`;

const StyledImageContainer = styled('div')`
    flex-shrink: 0;
    position: relative;
    height: 200px;
    margin-bottom: 15px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        width: 410px;
        height: 230px;
        margin-right: 30px;
        margin-bottom: 0;
    }
`;

const StyledContentContainer = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledPriceContainer = styled('div')`
    font-size: 20px;
    font-weight: ${props => props.theme.fontWeights.medium};
    margin-bottom: 10px;
`;

const StyledQuantityContainer = styled('div')`
    margin-bottom: 40px;
    
    p {
        margin: 0;
    }
    
    p:nth-child(2) {
        font-weight: ${props => props.theme.fontWeights.medium};
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        margin-bottom: 0;
    }
`;

const StyledButtonsContainer = styled('div')`
    margin-top: auto;
    display: flex;
    justify-content: space-between;
`;

const AddedProductPopup = (props) => {
    const context = React.useContext(Context);
    const { product, setShowPopup, count, size } = props;
    const priceInfoText = product.is_self_paid ? <>(incl. VAT. <a href={context.faqUrl} target='_blank' rel="noreferrer">More information.</a>)</> : '(per packaging unit)';

    return (
        <Popup setShowPopup={setShowPopup}>
            <StyledHeadlineContainer><h3>Added to shopping cart</h3></StyledHeadlineContainer>

            <StyledBodyContainer>
                <StyledImageContainer>
                    <ImageWrapper dangerouslySetInnerHTML={{ __html: product.image.list }}/>
                </StyledImageContainer>
                <StyledContentContainer>
                    <StyledPriceContainer>{product.price} € {priceInfoText}</StyledPriceContainer>
                    { size  &&
                        <StyledQuantityContainer>
                            <p>Size</p>
                            <p>{size}</p>
                        </StyledQuantityContainer>
                    }
                    <StyledQuantityContainer>
                        <p>Quantity</p>
                        <p>{count}</p>
                    </StyledQuantityContainer>
                    <StyledButtonsContainer>
                        <Button inverted>
                            <Link to='/'>
                                Back to shop
                            </Link>
                        </Button>

                        <Button>
                            <Link to='/checkout'>Checkout<ButtonArrow/></Link>
                        </Button>
                    </StyledButtonsContainer>
                </StyledContentContainer>
            </StyledBodyContainer>
        </Popup>
    )
}

export default AddedProductPopup;
