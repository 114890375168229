import React from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { ReactComponent as Logo} from '../../../icons/logo.svg';
import { ReactComponent as UserIcon} from '../../../icons/user.svg';
import { ReactComponent as CartIcon} from '../../../icons/cart.svg';
import { Context } from '../../00_utilities/context';
import SlideOutMenu from '../../03_molecules/SlideOutMenu';


const StyledHeader = styled('header')`
    top: 0;
    height: 62px;
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    color: white;
    font-size: 17px;
    background-color: ${props => props.theme.colors.pink};
    z-index: 6;
    pointer-events:  ${props => props.popupActive ? 'none' : 'all'};

    ${props => {
        if (props.popupActive) {
            return `
                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(33,33,33,0.5);
                    content: '';
                }
            `
        }
    }}
`;

const StyledHeaderLink = styled(Link)`
    color: white;
    margin-left: 16px;
    margin-top: 8px;
    position: relative;
        
    &:hover {
        cursor: pointer;
     }
    
    @media (min-width: ${props => props.theme.breakpoints.sm.breakpoint}px) {
        &::after {
            position: absolute;
            top: -5px;
            right: 14px;
            height: 29px;
            content: '';
            border-right: 1px solid white;
        }
    }
`;

const StyledHeaderTitle = styled('span')`
    margin-top: 0;
    display: none;
    font-weight: ${props => props.theme.fontWeights.medium};
    letter-spacing: -0.02rem;
    
    @media (min-width: ${props => props.theme.breakpoints.sm.breakpoint}px) {
        display: block;
    }
`;

const StyledLogo = styled(Logo)`
    width: 155px;
    height: 21px;
    fill: white;
    margin-right: 28px;
`;

const StyledMetaNavigation = styled('div')`
    position: absolute;
    right: 0;
    display: flex;
`;

const StyledUserElement = styled('div')`
    margin-right: 20px;
    margin-top: 5px;
    
    svg {
        fill: white;
        height: 1.208rem;
        width: 1.359rem;
    }
    
    &:hover {
        cursor: pointer;
    }
`;

const StyledCartElement = styled(Link)`
    margin-right: 20px;
    margin-top: 5px;
    text-decoration: none;
    position: relative;
    
    svg {
        fill: white;
        height: 1.208rem;
        width: 1.359rem;
    }
    
    .cart-element__counter {
        display: flex;
        min-width: 8px;
        align-items: center;
        justify-content: center;
        position: absolute;
        background-color: ${props => props.theme.colors.pink};
        color: ${props => props.theme.colors.white};
        padding: 0;
        font-size: 12px;
        line-height: 12px;
        margin-right: -4px;
        border-radius: 100%;
    }
`;

const StyledCartIconContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
`;

function Header(props) {
    const [slideOutActive, setSlideOutActive] = React.useState(false);
    const context = React.useContext(Context);
    const slideOutMenuRef = React.useRef(null);
    const { popupActive } = props;
    let time = 0;

    React.useEffect(() => {
        if (slideOutActive) {
            document.querySelector('body').addEventListener('click', bodyEventListener);
        }
        if (!slideOutActive) {
            document.querySelector('body').removeEventListener('click', bodyEventListener);
        }
    }, [slideOutActive]); // eslint-disable-line

    const bodyEventListener = (e) => {
        const { target } = e;
        const refExists = slideOutMenuRef && slideOutMenuRef.current !== null;
        const isSlideOutMenu = refExists && (target === slideOutMenuRef.current || slideOutMenuRef.current.contains(target));

        if (!isSlideOutMenu) {
            document.querySelector('body').removeEventListener('click', bodyEventListener);
            setSlideOutActive(false);
        }
    }

    const sumItems = (items) => {
        return Object.values(items).reduce((previousItem, currentItem) => {
            return previousItem + parseInt(currentItem.count, 10);
        }, 0);
    }

    const startTimer = () => {
        time = (new Date()).getTime();
    }

    const stopTimer = () => {
        const style = document.getElementsByTagName('html')[0].style;
        const stopTime = (new Date()).getTime();
        time = Math.abs((stopTime - time)/1000);

        if (time > 3 && time < 6) {
            style.filter = style.filter !== '' ? '' : 'hue-rotate(225deg)';
        }

        if (time > 6 && time < 9) {
            style.transform = style.transform !== '' ? '' : 'scaleX(-1)';
        }

        if (time > 9) {
            style.transform = '';
            style.filter = '';
        }

        time = 0;
    }

    const counterText = context.cart.items && sumItems(context.cart.items) !== 0 ? sumItems(context.cart.items) : '';

    return (
        <StyledHeader popupActive={popupActive}>
            <StyledHeaderLink to='/' onMouseDown={startTimer} onMouseUp={stopTimer} >
                <StyledLogo/>
            </StyledHeaderLink>
            <StyledHeaderTitle>Brand Shop</StyledHeaderTitle>

            <StyledMetaNavigation>
                <StyledUserElement onClick={() => { setSlideOutActive(!slideOutActive); }}><UserIcon/></StyledUserElement>
                <StyledCartElement to='/cart'>
                    <StyledCartIconContainer>
                        <CartIcon/>
                        <div className='cart-element__counter'>
                            <span>{counterText}</span>
                        </div>
                    </StyledCartIconContainer>
                </StyledCartElement>
            </StyledMetaNavigation>

            <SlideOutMenu bodyEventListener={bodyEventListener} ref={slideOutMenuRef} active={slideOutActive} setActive={setSlideOutActive} popupActive={popupActive}/>
        </StyledHeader>
    );
}

export default Header;
