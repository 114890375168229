import React from 'react';
import styled from 'styled-components';
import Button from '../../02_atoms/Button';
import { Link } from 'react-router-dom';
import { ReactComponent as ButtonArrow } from '../../../icons/arrow-right-long.svg';
import ProductInfoTable from '../ProductInfoTable';
import ImageWrapper from '../../01_base/layout/ImageWrapper';
import { themeSettings } from '../../07_themes/Default/index';

const StyledProductCard = styled('div')`
`;

const StyledProductCardInnerWrapper = styled('div')`
    background-color: ${props => props.theme.colors.white};
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const breakpointsHeights = {
    default: 211,
    max: 226,
    xxl: 151,
    xl: 199,
    lg: 173,
    mdair: 180,
    md: 177,
    sm: 313,
    ssm: 203,
    xs: 200,
};

const StyledImageContainer = styled('div')`
    position: relative;
    height: 200px;
    flex-shrink: 0;
    ${Object.keys(themeSettings.breakpoints)
        .map(
            (key) => `@media (min-width: ${themeSettings.breakpoints[key].breakpoint}px) {
        height: ${breakpointsHeights[key]}px;
      }`
        )
        .join('\n')};
`;

const StyledProductTitle = styled('h3')`
    margin-bottom: 10px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        margin-bottom: 20px;
    }
`;

const StyledContentContainer = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 30px;
    height: 100%;
`;

const StyledProductInfoTableContainer = styled('div')`
    margin-bottom: 20px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        margin-bottom: 60px;
    }
`;

const StyledButtonContainer = styled('div')`
    margin-top: auto;
`;

function ProductCard (props) {
    const { product } = props;

    return (
        <StyledProductCard className='product-card'>
            <StyledProductCardInnerWrapper className='product-card__inner-wrapper'>
                <StyledImageContainer>
                    <ImageWrapper dangerouslySetInnerHTML={{ __html: product.image.list }}></ImageWrapper>
                </StyledImageContainer>

                <StyledContentContainer>
                    <StyledProductTitle>{product.title}</StyledProductTitle>

                    <StyledProductInfoTableContainer>
                        <ProductInfoTable product={product} merged/>
                    </StyledProductInfoTableContainer>

                    <StyledButtonContainer>
                        <Button>
                            <Link to={`/detail/${product.id}`}>More <ButtonArrow/></Link>
                        </Button>
                    </StyledButtonContainer>
                </StyledContentContainer>
            </StyledProductCardInnerWrapper>
        </StyledProductCard>
    )
}

export default ProductCard;
