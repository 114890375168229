import React from "react";
import { Context } from '../../00_utilities/context';
import Button from '../../02_atoms/Button';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import styled from 'styled-components';
import { ReactComponent as PencilIcon} from '../../../icons/edit.svg';
import DeliveryAddress from '../DeliveryAddress';
import { ReactComponent as SaveIcon } from '../../../icons/save.svg';

const StyledCheckoutAddressInformation = styled('div')`
    padding: 0 30px;
`;

const StyledInfoEditElement = styled('div')`
    margin-top: ${props => props.isEditing ? '0' : '30px'};
`;

const CheckoutAddressInformation = () => {
    const [loading, setLoading] = React.useState(false);
    const [customerInState, setCustomerInState] = React.useState(null);
    const [isEditing, setIsEditing] = React.useState(false);
    const context = React.useContext(Context);
    const { customer } = context;

    React.useEffect(() => {
        setCustomerInState(customer);
    }, [customer]);

    const fieldChangeFlags = () => {
        const changes = {};
        const customerKeys = Object.keys(customer);

        for (let i = 0; i < customerKeys.length; i += 1) {
            const key = customerKeys[i];

            changes[key] = customer[key] !== customerInState[key];
        }

        return changes;
    }

    const fieldsHaveChanged = () => {
        const flags = fieldChangeFlags();

        return Object.values(flags).includes(true);
    }

    const changeHandler = (e) => {
        const {name, value} = e.currentTarget;
        updateCustomer(name, value);
    }

    const updateCustomer = (key, value) => {
        const newCustomerInstate = Object.assign({}, customerInState);
        newCustomerInstate[key] = value;
        setCustomerInState(newCustomerInstate);
    }

    const getValueByKey = (key) => {
        return (customerInState !== null && key in customerInState) ? customerInState[key] : '';
    }

    const editProfile = () => {
        setIsEditing(true);
    };

    const saveProfile = () => {
        if (!fieldsHaveChanged()) {
            setIsEditing(false);

            return;
        }

        setLoading(true);

        const body = {
            email: context.userEmail,
            data: customerInState,
        }

        urlFetch('/api/customers/update', { successCallback: (response) => { setLoading(false); setIsEditing(false); context.updateCustomer(response); } }, null, 'POST', null, body, context.token);
    };

    const profileEditingClickHandler = isEditing ? saveProfile : editProfile;

    const buttonText = isEditing ? 'Save' : 'Edit invoice address';
    const icon = isEditing ? <SaveIcon/> : <PencilIcon/>;
    let editButton = null;

    if (customerInState !== null && Object.keys(customerInState).length > 0) {
        editButton = <Button loading={loading} clickHandler={profileEditingClickHandler}>{buttonText} {icon}</Button>;
    }

    return (
        <StyledCheckoutAddressInformation>
            <div>Please enter your private address in this section. Your address will be used for the invoice that will be provided within the checkout process.</div>

            <DeliveryAddress isEditing={isEditing} getValueByKey={getValueByKey} changeHandler={changeHandler}/>

            <StyledInfoEditElement isEditing={isEditing}>
                { editButton }
            </StyledInfoEditElement>
        </StyledCheckoutAddressInformation>
    )
}

export default CheckoutAddressInformation;
