import React, {useEffect, useState} from "react";

const Select = (props) => {
    const [infoText, setInfoText] = useState('');
    const [textColor, setTextColor] = useState('');
    const { name, value, options, changeHandler, isEditing } = props;
    const optionElements = [];

    useEffect(() => {
        if (!options) {
            return null;
        }

        for (let i = 0; i < options.length; i += 1) {
            const value = options[i].value;
            const infoText = options[i].infoText;
            const textColor = options[i].textColor;
            if (value === props.value) {
                if (infoText) setInfoText(infoText);
                if (textColor) setTextColor(textColor);
                break;
            }
        }
    }, [props.value, options]);

    if (!options) {
        return null;
    }

    optionElements.push(
        <option key={'select-option-default'} value="">Please select</option>
    )

    for (let i = 0; i < options.length; i += 1) {
        const key = options[i].key;
        const value = options[i].value;
        const infoText = options[i].infoText;
        const textColor = options[i].textColor;

        optionElements.push(
            <option key={`select-option-${key}`}
                    value={value}
                    data-info-text={infoText}
                    data-text-color={textColor}
            >
                {key ? key : value}
            </option>
        )
    }

    const selectChangeHandler = (e) => {
        setInfoText(null);
        const selectedOptionInfoText = e.target.options[e.target.selectedIndex].getAttribute('data-info-text');
        if (selectedOptionInfoText) setInfoText(selectedOptionInfoText);

        setTextColor(null);
        const selectedOptionTextColor = e.target.options[e.target.selectedIndex].getAttribute('data-text-color');
        if (selectedOptionTextColor) setTextColor(selectedOptionTextColor);

        changeHandler(e);
    }

    return (
        <>
            <select name={name} value={value} onChange={selectChangeHandler} disabled={!isEditing}>
                { optionElements }
            </select>
            <span style={{fontSize: '14px', marginTop: (isEditing ? '-30px' : 0), color: (textColor ? textColor : '') }}  dangerouslySetInnerHTML={{ __html: infoText}} />
        </>
    )
}

export default Select;
