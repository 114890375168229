module.exports = (property, minimumViewWidth, maximumViewWidth, minimumValue, maximumValue, units = 'px') => {
    return `
        ${property}: ${minimumValue}px;
        
        @media screen and (min-width: ${minimumViewWidth}px) {
            ${property}: calc(${minimumValue}${units} + ${maximumValue - minimumValue} * ((100vw - ${minimumViewWidth}${units}) / ${maximumViewWidth - minimumViewWidth}));
        }

        @media screen and (min-width: ${maximumViewWidth}px) {
            ${property}: ${maximumValue}px;
        }
    `
};
