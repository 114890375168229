import cssLock from './cssLock';
import { themeSettings } from '../../07_themes/Default';

export default function componentSidePaddings() {
    const { breakpoints } = themeSettings;

    return `
        ${cssLock('padding-left', breakpoints.sm.breakpoint, breakpoints.xxl.breakpoint, 15, 240)};
        ${cssLock('padding-right', breakpoints.sm.breakpoint, breakpoints.xxl.breakpoint, 15, 240)};
    `
};
