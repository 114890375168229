import DefaultPage from '../../05_templates/Default';
import React from 'react';
import { Context } from '../../00_utilities/context';
import CustomerInformation from '../../03_molecules/CustomerInformation';
import ComponentContainer from '../../01_base/layout/ComponentContainer';
import ContentContainer from '../../01_base/layout/ContentContainer';
import HomeLink from '../../02_atoms/HomeLink';
import styled from 'styled-components';

const StyledUsername = styled('span')`
    font-weight: ${props => props.theme.fontWeights.medium};
`;

function Profile() {
    const context = React.useContext(Context);
    const hasFirstName = !!context.customer.firstName && context.customer.firstName !== '';
    const hasLastName = !!context.customer.lastName && context.customer.lastName !== '';
    const showUsername = hasFirstName && hasLastName;

    const usernameElement = showUsername ? <StyledUsername>{showUsername ? `${context.customer.firstName} ${context.customer.lastName}` : ''}</StyledUsername> : null;
    let emailElement = context.userEmail ? context.userEmail : null;

    if (showUsername && emailElement !== null) {
        emailElement = `(${emailElement})`;
    }

    return (
        <DefaultPage>
            <ComponentContainer>
                <ContentContainer>
                    <HomeLink/>
                    <CustomerInformation usernameElement={usernameElement} emailElement={emailElement}/>
                </ContentContainer>
            </ComponentContainer>
        </DefaultPage>
    );
}

export default Profile;
