import DefaultPage from '../../05_templates/Default';
import React  from 'react';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import { Link } from 'react-router-dom';
import { Context } from '../../00_utilities/context';
import styled from 'styled-components';
import ComponentContainer from '../../01_base/layout/ComponentContainer';
import ContentContainer from '../../01_base/layout/ContentContainer';
import HomeLink from '../../02_atoms/HomeLink';
import CartElement from '../../03_molecules/CartElement';
import { ReactComponent as ButtonArrow} from '../../../icons/arrow-right-long.svg';
import Button from '../../02_atoms/Button';
import NoProductsContainer from '../../03_molecules/NoProductsContainer';

const StyledContentContainer = styled(ContentContainer)`
    .cart-element {
        padding-left: 15px;
        padding-right: 15px;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            padding-left: 32px;
            padding-right: 32px;
        }
    }
`;

const StyledCheckoutElement = styled('div')`
    display: flex;
    justify-content: space-between;
    background-color: ${props => props.theme.colors.whiteSmoke};
    border-bottom: 1px solid #434549;
    padding: 25px 15px;
    margin-top: 50px;
    
    .checkout-element__total-title-container {
        display: flex;
        align-items: flex-start;
        
        h4 {
            margin-bottom: 0;
        }

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            align-items: center;
        }
    }
    
    .checkout-element__total-sum-container {
        h4 {
            margin-bottom: 15px;
        }
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            h4 {
                margin-bottom: 0;
                margin-right: 20px;
            }
            
            flex-direction: row;
            align-items: center;
        }
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        padding: 32px;
    }
`;

function Cart() {
    const [products, setProducts] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const context = React.useContext(Context);

    React.useEffect(() => {
        const itemsArray = 'items' in context.cart ? Object.values(context.cart.items) : null;

        if (itemsArray !== null && itemsArray.length > 0) {
            const ids = Object.values(context.cart.items).map((item) => {
                return item.product_id;
            });

            urlFetch('/api/products', { successCallback: (response) => { setLoading(false); setProducts(response.elements); } }, null, 'POST', {id: ids, limit: 100}, { email: context.userEmail }, context.token);
        }

        if (itemsArray === null || itemsArray.length === 0) {
            setProducts([]);
            setLoading(false);
        }
    }, [context.cart.items]); // eslint-disable-line

    const CartElements = () => {
        const elements = [];
        for (let i = 0; i < products.length; i += 1) {
            const product = products[i];
            const cartItem = context.cart.items[product.id];

            if (typeof cartItem === 'undefined') {
                continue;
            }

            elements.push(<CartElement setLoading={setLoading} cartItem={cartItem} product={product} key={`cart-element-${i}`}/>)
        }

        return (
            <div>{elements}</div>
        )
    }

    const CheckoutElement = () => {
        let sum = 0;

        for (let i = 0; i < products.length; i += 1) {
            const product = products[i];
            const cartItem = context.cart.items[product.id];

            if (typeof cartItem === 'undefined') {
                continue;
            }

            sum += (parseFloat(product.price.replace(',', '.')) * parseInt(cartItem.count));
        }

        sum = sum.toFixed(2).replace('.', ',');

        return (
            <StyledCheckoutElement>
                <div className='checkout-element__total-title-container'>
                    <h4>Total</h4>
                </div>

                <div className='checkout-element__total-sum-container'>
                    <h4>Sum: {sum} € (incl. VAT. <a href={context.faqUrl} target='_blank' rel="noreferrer">More information.</a>)</h4>
                    <Button>
                        <Link to='/checkout'>Checkout <ButtonArrow/></Link>
                    </Button>
                </div>
            </StyledCheckoutElement>
        )
    }

    return (
        <DefaultPage loading={loading}>
            <ComponentContainer>
                <StyledContentContainer>
                    <HomeLink/>

                    <h1>Your Shopping Cart</h1>
                    <hr/>
                    { products.length > 0 ? <CartElements/> : <NoProductsContainer/> }
                    { products.length > 0 && <CheckoutElement/> }
                </StyledContentContainer>
            </ComponentContainer>
        </DefaultPage>
    );
}

export default Cart;
