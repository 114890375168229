import DefaultPage from '../../05_templates/Default';
import React from 'react';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import { Link, Redirect } from 'react-router-dom';
import { Context } from '../../00_utilities/context';
import styled from 'styled-components';
import Button from '../../02_atoms/Button';
import ComponentContainer from '../../01_base/layout/ComponentContainer';
import ContentContainer from '../../01_base/layout/ContentContainer';
import HomeLink from '../../02_atoms/HomeLink';
import CartElement from '../../03_molecules/CartElement';
import { ReactComponent as ArrowDownIcon } from '../../../icons/empty-arrow-down.svg';
import { ReactComponent as ButtonArrow } from '../../../icons/arrow-right-long.svg';
import CheckoutAddressInformation from '../../03_molecules/CheckoutAddressInformation';
import CheckoutPaymentInformation from '../../03_molecules/CheckoutPaymentInformation';
import NoProductsContainer from '../../03_molecules/NoProductsContainer';

const StyledProgressBar = styled('div')`
    display: flex;
    flex-direction: row;
`;

const StyledProgressStep = styled('div')`
    position: relative;
    width: 100%;
    padding-left: 10px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: ${props => props.active ? props.theme.colors.pink : props.theme.colors.whiteSmoke};
    color: ${props => props.active ? props.theme.colors.white : props.theme.colors.black};
    font-size: 12px;
    
    &:hover {
        cursor: ${props => !props.disabled ? 'pointer' : 'default'};
    }
    
    &:nth-child(1) {
        z-index: 3;
    }
    
    &:nth-child(2) {
        z-index: 2;
        padding-left: 15px;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            padding-left: 30px;
        }
    }
    
    &:nth-child(3) {
        z-index: 1;
        padding-left: 15px;
        margin-right: 10px;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            margin-right: 15px;
            padding-left: 30px;
        }
    }
    
    &::after {
        position: absolute;
        top: 0;
        right: -10px;
        display: inline-block;
        content: '';
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 10px solid ${props => props.active ? props.theme.colors.pink : props.theme.colors.whiteSmoke};
        width: 0;
        height: 0;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            right: -15px;
            border-top: 21px solid transparent;
            border-bottom: 21px solid transparent;
            border-left: 15px solid ${props => props.active ? props.theme.colors.pink : props.theme.colors.whiteSmoke};
        }
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        font-size: 16px;
        height: 42px;
    }
`;

const StyledCheckoutAccordion = styled('div')`

`;

const StyledCheckoutAccordionItem = styled('div')`

`;

const StyledCheckoutAccordionItemHeader = styled('div')`
    position: relative;
    color: ${props => props.active ? props.theme.colors.pink : props.theme.colors.black};
    padding: 35px 15px;
    border-bottom: 1px solid ${props => props.active ? props.theme.colors.whiteSmoke : props.theme.colors.black};
    font-weight: ${props => props.theme.fontWeights.medium};

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        font-size: 20px;
        padding: 35px 30px;
    }
    
    &:hover {
        cursor: ${props => props.active ? 'default' : (props.disabled ? 'default' : 'pointer')};
    }

    .arrow-down-icon {
        position: absolute;
        top: 34px;
        right: 32px;
        width: 32px;
        height: 32px;
        transform: rotate(${props => props.active ? '180deg' : '0deg'});
        
        path {
            fill: ${props => props.theme.colors.pink};
        }
    }
`;

const StyledCheckoutAccordionItemBody = styled('div')`
    display: ${props => props.active ? 'block' : 'none'};
`;


const StyledPartSumText = styled('span')`
    color: ${props => props.theme.colors.lightGrey};
`;

const StyledErrorText = styled('span')`
    font-weight: 700;
    color: ${props => props.theme.colors.pinkDark};
`;

const StyledNextStepElement = styled('div')`
    display: flex;
    justify-content: space-between;
    background-color: ${props => props.theme.colors.whiteSmoke};
    border-bottom: 1px solid #434549;
    padding: 20px;
    margin-top: 50px;
    
    .next-step-element__total-title-container {
        display: flex;
        align-items: flex-start;
        
        h4 {
            margin-bottom: 0;
        }

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            align-items: center;
        }
    }
    
    .next-step-element__total-sum-container {
        h4 {
            margin-bottom: 15px;
        }
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            h4 {
                margin-bottom: 0;
                margin-right: 20px;
            }
            
            flex-direction: row;
            align-items: center;
        }
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        padding: 32px;
    }
`;

function Checkout() {
    const CHECKOUT_TYPE_FULL = 'full';
    const CHECKOUT_TYPE_SELF_PAID = 'self';
    const CHECKOUT_TYPE_INTERNALLY_PAID = 'internally';

    const [products, setProducts] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [loadingCheckout, setLoadingCheckout] = React.useState(false);
    const [checkoutSuccess, setCheckoutSuccess] = React.useState(false);
    const [successImage, setSuccessImage] = React.useState(null);
    const [checkoutSelfPaidOnSuccessPage, setCheckoutSelfPaidOnSuccessPage] = React.useState(false);
    const [orderNumber, setOrderNumber] = React.useState('');
    const [step, setStep] = React.useState(0);
    const context = React.useContext(Context);
    const { customer } = context;
    const costCenterIsSet = 'internalCostCenter' in customer && !!customer['internalCostCenter'];
    const companyCodeIsSet = 'companyCode' in customer && !!customer['companyCode'];

    const [checkoutType, setCheckoutType] = React.useState(CHECKOUT_TYPE_FULL);
    const [termsAccepted, setTermsAccepted] = React.useState(false);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        const itemsArray = 'items' in context.cart ? Object.values(context.cart.items) : null;

        if (itemsArray !== null && itemsArray.length > 0) {
            const ids = Object.values(context.cart.items).map((item) => {
                return item.product_id;
            });

            urlFetch('/api/products', {
                successCallback: (response) => {
                    const products = response.elements;
                    setLoading(false);
                    setProducts(products);
                    updateCheckoutTypeByProducts(products);
                }
            }, null, 'POST', {id: ids, limit: 100}, {email: context.userEmail}, context.token);
        }

        if (itemsArray === null || itemsArray.length === 0) {
            setProducts([]);
            setLoading(false);
        }
    }, [context.cart.items]); // eslint-disable-line

    const getCheckouts = (products) => {
        const hasSelfPaid = products.length > 0 && products.some((product) => { return product.is_self_paid });
        const hasInternallyPaid = products.length > 0 && products.some((product) => { return !product.is_self_paid });

        return {hasSelfPaid, hasInternallyPaid};
    }

    const updateCheckoutTypeByProducts = (products) => {
        let newCheckoutType = CHECKOUT_TYPE_FULL;
        const checkouts = getCheckouts(products);
        const hasSelfPaid = !!checkouts.hasSelfPaid;
        const hasInternallyPaid = !!checkouts.hasInternallyPaid;

        if (hasSelfPaid) {
            newCheckoutType = CHECKOUT_TYPE_SELF_PAID;
        }

        // Internally first
        if (hasInternallyPaid && hasSelfPaid) {
            newCheckoutType = CHECKOUT_TYPE_INTERNALLY_PAID;
        }

        setCheckoutType(newCheckoutType);
    }

    const handleCheckoutResponse = (response) => {
        setLoadingCheckout(false);

        // AFTER CHECKOUT API CALL:
        // checkoutType === CHECKOUT_TYPE_INTERNALLY_PAID => Checkout self paid on success screen (checkoutSelfPaidOnSuccessPage === true)
        // checkoutType === CHECKOUT_TYPE_FULL => Redirect to success screen (just like before extension to stripe payment)
        // checkoutType === CHECKOUT_TYPE_SELF_PAID => Redirect to session_url from response

        if (response.error) {
            context.updateCart(response.cart_left);
            setError(response.statusText || "Failed");
            setCheckoutSuccess(false);
            return;
        }

        switch (checkoutType) {
            case CHECKOUT_TYPE_FULL:
                context.updateCart(response.cart_left);

                setCheckoutSuccess(true);
                setOrderNumber(response.order_number);
                setSuccessImage(response.image);
                break;

            case CHECKOUT_TYPE_INTERNALLY_PAID:
                context.updateCart(response.cart_left);
                setCheckoutSelfPaidOnSuccessPage(true);

                setCheckoutSuccess(true);
                setOrderNumber(response.order_number);
                setSuccessImage(response.image);

                break;
            case CHECKOUT_TYPE_SELF_PAID:
                if (!('checkout_session_url' in response) || !(typeof response.checkout_session_url === 'string')) {
                    alert(`Checkout Session URL missing in response for order ${response.order_number}. Please contact your admin with this message.`);
                    context.updateCart({});

                    return;
                }

                context.updateCart(response.cart_left);
                window.location.href = response.checkout_session_url;
                break;
            default: console.log('no checkout type set.'); break;
        }
    }

    const checkout = () => {
        setLoadingCheckout(true);

        urlFetch('/api/carts/checkout', {
            successCallback: handleCheckoutResponse,
        }, null, 'POST', null, {email: context.userEmail, checkout_type: checkoutType}, context.token);
    }

    const CartElements = () => {
        const selfPaidProducts = [];
        const selfPaidElements = [];
        const internallyPaidProducts = [];
        const internallyPaidElements = [];

        if (typeof context.cart.items === 'undefined') {
            return [];
        }

        for (let i = 0; i < products.length; i += 1) {
            const product = products[i];
            const cartItem = context.cart.items[product.id];

            if (typeof cartItem === 'undefined') {
                continue;
            }

            if (product.is_self_paid) {
                selfPaidProducts.push(product);
                selfPaidElements.push(<CartElement cartItem={cartItem} product={product} key={`cart-element-${i}`}/>)
            }

            if (!product.is_self_paid) {
                internallyPaidProducts.push(product);
                internallyPaidElements.push(<CartElement cartItem={cartItem} product={product} key={`cart-element-${i}`}/>)
            }
        }

        const haveInternallyPaidElements = internallyPaidElements.length > 0;
        const haveSelfPaidElements = selfPaidElements.length > 0;

        const internallyPaidText = haveSelfPaidElements ? <h4 style={{marginBottom: 0}}>Paid via internal cost center <StyledPartSumText>({ getSum(internallyPaidProducts) } €)</StyledPartSumText></h4> : null;
        const selfPaidText = haveInternallyPaidElements ? <h4 style={{marginBottom: 0}}>Self paid <StyledPartSumText>({ getSum(selfPaidProducts) } €)</StyledPartSumText></h4> : null;

        return (
            <div>
                {
                    haveInternallyPaidElements &&
                    <div>
                        { haveSelfPaidElements && <br/>}
                        { internallyPaidText }
                        { internallyPaidElements }
                        { haveSelfPaidElements && <br/>}
                    </div>
                }
                {
                    selfPaidElements.length > 0 &&
                    <div>
                        { haveInternallyPaidElements && <br/>}
                        { selfPaidText }
                        { selfPaidElements }
                    </div>
                }
            </div>
        )
    }

    const getSum = (productsForSum = null) => {
        const usedProducts = productsForSum ? productsForSum : products;
        let sum = 0;

        if (typeof context.cart.items === 'undefined') {
            return '';
        }

        for (let i = 0; i < usedProducts.length; i += 1) {
            const product = usedProducts[i];
            const cartItem = context.cart.items[product.id];

            if (typeof cartItem === 'undefined') {
                continue;
            }

            sum += (parseFloat(product.price.replace(',', '.')) * parseInt(cartItem.count));
        }

        return sum.toFixed(2).replace('.', ',');
    }

    const updateStep = (step) => {
        window.scrollTo(0, 0);
        setStep(step);
    }

    const updateTermsAccepted = (e) => {
        setTermsAccepted(e.target.checked);
    }

    const getCheckoutButton = () => {
        if (!context.getIsCustomerComplete(context.customer, checkoutType)) {
            return (
                <Button>
                    <Link to='/profile'>Complete profile first<ButtonArrow/></Link>
                </Button>
            );
        }

        if (checkoutType !== CHECKOUT_TYPE_SELF_PAID && !costCenterIsSet) {
            return (
                <Button disabled>
                    <span>Update cost center first <ButtonArrow/></span>
                </Button>
            );
        }

        if (checkoutType !== CHECKOUT_TYPE_SELF_PAID && !companyCodeIsSet) {
            return (
                <Button disabled>
                    <span>Update Company Code <ButtonArrow/></span>
                </Button>
            );
        }

        if (checkoutType === CHECKOUT_TYPE_SELF_PAID && !termsAccepted) {
            return (
                <Button disabled>
                    <span className='interactive-link'>Accept terms first <ButtonArrow/></span>
                </Button>
            )
        }

        return (
            <Button loading={loadingCheckout}>
                <span className='interactive-link' onClick={checkout}>Place order <ButtonArrow/></span>
            </Button>
        )
    }

    let priceInfoText = '';

    // Only self paid or internally paid + self paid
    if (checkoutType === CHECKOUT_TYPE_SELF_PAID || checkoutType === CHECKOUT_TYPE_INTERNALLY_PAID) {
        priceInfoText = <>(incl. VAT. <a href={context.faqUrl} target='_blank' rel="noreferrer">More information.</a>)</>;
    }

    return (
        <DefaultPage loading={loading}>
            { checkoutSuccess && orderNumber !== '' && successImage !== null && <Redirect to={{pathname: '/success', state: { orderNumber, successImage, checkoutSelfPaidOnSuccessPage }} }/> }

            <ComponentContainer>
                <ContentContainer>
                    <HomeLink/>
                    <h1>Checkout</h1>

                    <StyledProgressBar>
                        <StyledProgressStep disabled={products.length === 0} active={step === 0} onClick={() => { products.length > 0 && updateStep(0); }}><span>Shopping Cart</span></StyledProgressStep>
                        {checkoutType === CHECKOUT_TYPE_SELF_PAID &&
                            <StyledProgressStep disabled={products.length === 0} active={step === 1} onClick={() => { products.length > 0 && updateStep(1); }}><span>Invoice</span></StyledProgressStep>
                        }
                        <StyledProgressStep disabled={products.length === 0} active={step === 2} onClick={() => { products.length > 0 && updateStep(2); }}><span>Payment</span></StyledProgressStep>
                    </StyledProgressBar>

                    <StyledCheckoutAccordion>
                        <StyledCheckoutAccordionItem>
                            <StyledCheckoutAccordionItemHeader disabled={products.length === 0} active={step === 0} onClick={() => { products.length > 0 && updateStep(0); }}>
                                <span>Shopping Cart</span>
                                <ArrowDownIcon className='arrow-down-icon'/>
                            </StyledCheckoutAccordionItemHeader>
                            <StyledCheckoutAccordionItemBody active={step === 0}>
                                { products.length > 0 ? <CartElements/> : <NoProductsContainer/> }

                                <StyledNextStepElement>
                                    <div className='next-step-element__total-title-container'>
                                        <h4>Total</h4>
                                    </div>

                                    <div className='next-step-element__total-sum-container'>
                                        <h4>Sum: {getSum()} €  {priceInfoText}</h4>
                                        <Button disabled={products.length === 0}>
                                            <span className='interactive-link' onClick={() => { updateStep(checkoutType === CHECKOUT_TYPE_SELF_PAID ? 1 : 2); }}>Next Step <ButtonArrow/></span>
                                        </Button>
                                    </div>
                                </StyledNextStepElement>
                            </StyledCheckoutAccordionItemBody>
                        </StyledCheckoutAccordionItem>

                        {checkoutType === CHECKOUT_TYPE_SELF_PAID &&
                            <StyledCheckoutAccordionItem>
                                <StyledCheckoutAccordionItemHeader disabled={products.length === 0} active={step === 1} onClick={() => { products.length > 0 && updateStep(1); }}>
                                    <span>Invoice</span>
                                    <ArrowDownIcon className='arrow-down-icon'/>
                                </StyledCheckoutAccordionItemHeader>
                                <StyledCheckoutAccordionItemBody active={step === 1}>
                                    <br/>

                                    <CheckoutAddressInformation/>

                                    <StyledNextStepElement>
                                        <div></div>

                                        <div>
                                            <Button>
                                                <span className='interactive-link' onClick={() => { updateStep(2); }}>Next Step <ButtonArrow/></span>
                                            </Button>
                                        </div>
                                    </StyledNextStepElement>
                                </StyledCheckoutAccordionItemBody>
                            </StyledCheckoutAccordionItem>
                        }

                        <StyledCheckoutAccordionItem>
                            <StyledCheckoutAccordionItemHeader disabled={products.length === 0} active={step === 2} onClick={() => { products.length > 0 && updateStep(2); }}>
                                <span>Payment</span>
                                <ArrowDownIcon className='arrow-down-icon'/>
                            </StyledCheckoutAccordionItemHeader>
                            <StyledCheckoutAccordionItemBody active={step === 2}>
                                <br/>

                                <CheckoutPaymentInformation checkoutType={checkoutType} updateTermsAccepted={updateTermsAccepted} termsAccepted={termsAccepted}/>

                                <StyledNextStepElement>
                                    {error && <StyledErrorText>Error: {error}</StyledErrorText>}
                                    <div></div>

                                    <div>
                                        { getCheckoutButton() }
                                    </div>

                                </StyledNextStepElement>
                            </StyledCheckoutAccordionItemBody>
                        </StyledCheckoutAccordionItem>
                    </StyledCheckoutAccordion>

                </ContentContainer>
            </ComponentContainer>
        </DefaultPage>
    );
}

export default Checkout;
