import React from "react";
import styled from "styled-components";
import ArrowDownIcon from '../../../icons/empty-arrow-down.svg';

const StyledSelectContainer = styled('div')`
    position: relative;

    &:before {
        content: '${props => props.floatingTitle}';
        color: #B4B1B3;
        font-size: 13px;
        position: absolute;
        top: 6px;
        left: 20px;
        z-index: 1;
    }
`;

const StyledSelect = styled('select')`
    background: url(${ArrowDownIcon}) no-repeat calc(100% - 15px) calc(50%);
    background-size: 14px;
    background-color: ${props => props.theme.colors.white};
    appearance: none;
    border: 1px solid #DFDEDF;
    border-radius: 4px;
    height: 55px;
    padding: 16px 30px 0 19px;
    width: 100%;
    color: ${props => props.theme.colors.black};
    position: relative;

    &.active,
    &:active,
    &:focus {
        background-color: ${props => props.theme.colors.white};
        background-size: 14px;
        color: ${props => props.theme.colors.black};
        outline: 0;
    }

    &.warning {
        border-color: red;
    }

    &:hover {
        cursor: pointer;
        border-color: #DFDEDF;
    }
`;

const SizeSelect = (props) => {
    const { name, value, changeHandler, sizes } = props;
    const options = sizes.map((size) => (
        <option
            key={`quantity-select-${size.id}`}
            disabled={size.id === '-1'}
            value={size.id === '-1' ? '' : size.value}
        >
            {size.value}
        </option>
    ));

    return (
        <StyledSelectContainer floatingTitle='Available sizes' className='size-select-container'>
            <StyledSelect name={name} value={value} onChange={changeHandler}>
                { options }
            </StyledSelect>
        </StyledSelectContainer>
    )
}

export default SizeSelect;
