import urlFetch from '../connectivity/urlFetch';

export default function updateCart(product, count, size, context) {
    count = parseInt(count);

    const upsertToCartObject = (product, count, size) => {
        const newCart = Object.assign({}, context.cart);
        const items = Object.assign({}, newCart.items);

        items[product.id] = {
            product_id: product.id,
            count: count,
            size: size,
            product: product.is_self_paid
        };

        newCart.items = items;

        if (count === 0) {
            delete newCart.items[product.id];
        }

        return newCart;
    }

    const data = upsertToCartObject(product, count, size);
    urlFetch('/api/carts/update', { successCallback: context.updateCart }, null, 'POST', null, { email: context.userEmail, data }, context.token);
}
