module.exports = (url, callbacks = null, urlBase = null, method = 'GET', queryParameters = null, body = null, token = null) => {
    const isAbsoluteUrl = /^https?:\/\//i.test(url);
    const localUrlBase = 'https://brainlabmarketingmaterial.ddev.site';
    const prodUrlBase = 'https://brandshop.brainlab.com';

    const defaultUrlBase = process.env.NODE_ENV === 'production' ? prodUrlBase : localUrlBase;
    let urlObject = null;

    if (isAbsoluteUrl) {
        urlObject = new URL(url);
    }

    if (!isAbsoluteUrl) {
        urlObject = new URL(url, urlBase !== null ? urlBase : defaultUrlBase);
    }

    if (urlObject === null) {
        throw Error(`urlObject could not be created (url: "${url}" | urlBase: "${urlBase}")`);
    }

    const fetchConfiguration = {
        method,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
    };

    if (token !== null) {
        fetchConfiguration.headers['x-token'] = `${token}`;
    }

    if (queryParameters !== null) {
        for (const key in queryParameters) {
            if (!queryParameters.hasOwnProperty(key)) {
                continue;
            }

            const parameterValue = queryParameters[key];

            if (Array.isArray(parameterValue)) {
                for (let i = 0; i < parameterValue.length; i += 1) {
                    urlObject.searchParams.set(`${key}[${i}]`, parameterValue[i]);
                }

                continue;
            }

            urlObject.searchParams.set(key, parameterValue);
        }
    }

    if (body !== null) {
        fetchConfiguration.body = JSON.stringify(body);
    }

    let promise = fetch(urlObject.toString(), fetchConfiguration);
    promise = promise.then((response) => {
        if (!response.ok) {
            if (callbacks !== null && callbacks.hasOwnProperty('serverErrorCallback')) {
                callbacks.serverErrorCallback(response);

                return response;
            }

            throw Error(`Server response error (code: ${response.status} | text: "${response.statusText}")`);
        }

        return response;
    }).then((result) => result.json());

    if (callbacks !== null && callbacks.hasOwnProperty('successCallback')) {
        promise.then(callbacks.successCallback)
    }

    if (callbacks !== null && callbacks.hasOwnProperty('clientErrorCallback')) {
        promise.catch((error) => {
            callbacks.clientErrorCallback(error);
        });
    }

    return promise;
}
