import React from "react";
import styled from "styled-components";
import Button from '../../02_atoms/Button';
import { Link } from 'react-router-dom';

const StyledNoOrdersContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px 30px;

    p {
        font-weight: ${props => props.theme.fontWeights.medium};
    }
`;

function NoOrdersContainer() {
    return (
        <StyledNoOrdersContainer>
            <p>There are no orders in your history, yet.</p>
            <Button><Link to='/'>Find products</Link></Button>
        </StyledNoOrdersContainer>
    );
}

export default NoOrdersContainer;
