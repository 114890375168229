import React from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import componentSidePaddings from '../../00_utilities/mixins/componentSidePaddings';
import { ReactComponent as ArrowIcon} from '../../../icons/arrow-left.svg';

const StyledHomeLinkWrapper = styled('div')`
    ${componentSidePaddings()};
    position: absolute;
    top: 10px;
    left: 0;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        top: 30px;
    }
`;

const StyledHomeLink = styled(Link)`
    color: ${props => props.theme.colors.pink};
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    
    svg {
        width: 10px;
        height: 10px;
        fill: ${props => props.theme.colors.pink};
    }
    
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

function HomeLink() {
    return (
        <StyledHomeLinkWrapper className='home-link-container'>
            <StyledHomeLink to='/'><ArrowIcon/> Return to home</StyledHomeLink>
        </StyledHomeLinkWrapper>
    );
}

export default HomeLink;
