import React from "react";
import styled from 'styled-components';
import overrideComponentSidePaddings from '../../00_utilities/mixins/overrideComponentSidePaddings';
import { ReactComponent as ArrowIcon } from '../../../icons/empty-arrow-down.svg';

const StyledImageSlider = styled('div')`
    position: relative;
    height: 200px;
    ${overrideComponentSidePaddings()};
    margin-bottom: 110px;
    overflow: hidden;

    @media (min-width: ${props => props.theme.breakpoints.md.breakpoint}px) {
        height: 300px;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        width: 50%;
        margin: 0;
    }

    @media (min-width: ${props => props.theme.breakpoints.xxl.breakpoint}px) {
        min-width: 700px;
        height: 400px;
    }
`;

const StyledSliderImageContainer = styled('div')`
    width: ${props => props.elementWidth}px;
    
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

const StyledSliderElementsContainer = styled('div')`
    display: flex;
    position: absolute;
    transition: left 500ms;
    left: ${props => props.offsetLeft};
    height: 100%;
`;

const StyledImageSliderArrowsContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
`;

const StyledImageSliderArrowContainer = styled('div')`
    pointer-events: all;
    
    &:hover {
        cursor: pointer;

        svg path {
            fill: ${props => props.theme.colors.pink};
        }
    }
    
    svg {
        width: 50px;
        height: 50px;
        
        path {
            fill: ${props => props.theme.colors.lightGrey};
        }
    }
    
    &:first-child {
        margin-left: 20px;
        
        svg {
            transform: rotate(90deg);
        }
    }
    
    &:last-child {
        margin-right: 20px;
        
        svg {
            transform: rotate(-90deg);
        }
    }

    @media (min-width: ${props => props.theme.breakpoints.max.breakpoint}px) {
        margin-top: 0;
    }
`;

const StyledImageSliderBubblesContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 15px;
    bottom: 10px;
    left: 0;
    z-index: 1;
    pointer-events: none;
`;

const StyledImageSliderBubblesInnerWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const StyledSliderBubble = styled('div')`
    width: ${props => props.active ? 13 : 10}px;
    height: ${props => props.active ? 13 : 10}px;
    border-radius: 100%;
    background-color: ${props => props.active ? props.theme.colors.pink : props.theme.colors.lightGrey};
    
    &:not(:last-child) {
        margin-right: 5px;
    }
`;

const ImageSlider = (props) => {
    const { imageMarkups } = props;
    let images = [];
    const [activeImage, setActiveImage] = React.useState(0);
    const [elementWidth, setElementWidth] = React.useState(0);
    const containerRef = React.useRef(null);

    React.useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setElementWidth(containerRef.current.offsetWidth);
            }
        }

        window.addEventListener('resize', handleResize);
        setElementWidth(containerRef.current.offsetWidth);
    }, []);

    for (let i = 0; i < imageMarkups.length; i++) {
        const imageMarkup = imageMarkups[i];

        images.push(
            <StyledSliderImageContainer elementWidth={elementWidth} key={`slider-image-container-${i}`} dangerouslySetInnerHTML={{ __html: imageMarkup }}/>
        )
    }

    const slideRight = () => {
        if (activeImage === images.length - 1) {
            return;
        }

        setActiveImage(activeImage + 1);
    }

    const slideLeft = () => {
        if (activeImage === 0) {
            return;
        }

        setActiveImage(activeImage - 1);
    }

    const getBubbles = () => {
        const bubbles = [];

        for (let i = 0; i < images.length; i++) {
            bubbles.push(<StyledSliderBubble active={activeImage === i} key={`image-slider-bubble-${i}`}/>)
        }

        return bubbles;
    }

    return <StyledImageSlider ref={containerRef} >
        {
            images.length > 1 &&
            <>
                <StyledImageSliderArrowsContainer>
                    <StyledImageSliderArrowContainer onClick={slideLeft}><ArrowIcon/></StyledImageSliderArrowContainer>
                    <StyledImageSliderArrowContainer onClick={slideRight}><ArrowIcon/></StyledImageSliderArrowContainer>
                </StyledImageSliderArrowsContainer>

                <StyledImageSliderBubblesContainer>
                    <StyledImageSliderBubblesInnerWrapper>
                        { getBubbles() }
                    </StyledImageSliderBubblesInnerWrapper>
                </StyledImageSliderBubblesContainer>
            </>
        }

        <StyledSliderElementsContainer offsetLeft={`-${activeImage * elementWidth}px`}>
            { images }
        </StyledSliderElementsContainer>
    </StyledImageSlider>;
}

export default ImageSlider;
