import styled from "styled-components";

const StyledFormContainer = styled('div')`
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;

    > .cost-center-info,
    > .form-input-container,
    > .form-input-column {
        padding: 0 15px;
        box-sizing: border-box;
    }
    
    > .cost-center-info {
        font-size: 14px;
        margin-bottom: 1rem;
    }
`;


const FormContainer = (props) => {
    return (
        <StyledFormContainer className='form-container'>{props.children}</StyledFormContainer>
    )
};

export default FormContainer;
