import DefaultPage from '../../05_templates/Default';
import React from 'react';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import { useParams } from 'react-router-dom';
import ComponentContainer from '../../01_base/layout/ComponentContainer';
import ContentContainer from '../../01_base/layout/ContentContainer';
import styled from "styled-components";

const StyledComponentContainerWrapper = styled('div')`
    table td {
        padding: unset;
    }
`;

function HeadlessContent() {
    const { key } = useParams();
    const [headlessContentLoaded, setHeadlessContentLoaded] = React.useState(false);
    const [headlessContent, setHeadlessContent] = React.useState(null);
    const lastTitle = document.title;

    React.useEffect(() => {
        urlFetch(`/api/headless-content/${key}`, { successCallback }, null, 'GET');

        return () => {
            document.title = lastTitle;
        }
    }, [headlessContentLoaded]); // eslint-disable-line

    const successCallback = (response) => {
        setHeadlessContentLoaded(true);
        setHeadlessContent(response);

        updateDocumentTitle(response);
    }

    const updateDocumentTitle = (response) => {
        if (response === null || !response.hasOwnProperty('title') ) {
            return null;
        }

        document.title = response.title;
    }

    const ContentWrapper = () => {
        if (headlessContent === null || !headlessContent.hasOwnProperty('elements') ) {
            return null;
        }

        const elements = headlessContent.elements.map((content, key) => {
            return <ComponentContainer key={`headless-content-element-${key}`}>
                <ContentContainer dangerouslySetInnerHTML={{ __html: content }}/>
            </ComponentContainer>;
        });

        return <StyledComponentContainerWrapper>{elements}</StyledComponentContainerWrapper>;
    }

    return (
        <DefaultPage loading={!headlessContentLoaded}>
            <ContentWrapper/>
        </DefaultPage>
    );
}

export default HeadlessContent;
