import DefaultPage from '../../05_templates/Default';
import React from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Context } from '../../00_utilities/context';
import ComponentContainer from '../../01_base/layout/ComponentContainer';
import HomeLink from '../../02_atoms/HomeLink';
import ContentContainer from '../../01_base/layout/ContentContainer';
import stripUrlParameter from '../../00_utilities/functions/stripUrlParameter';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import KeyVisual from '../../02_atoms/KeyVisual';
import LoadingIcon from '../../02_atoms/LoadingIcon';
import { ReactComponent as ErrorIcon} from '../../../icons/error.svg';

const StyledTextBox = styled('div')`
    .h1 {
        font-size: 32px;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            font-size: 40px;
        }
    }
`;

const StyleConfirmationLoadingText = styled('div')`
    display: flex;
    
    .loading-icon {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin: 0 10px 0 0;
    }
`;

const StyledConfirmedText = styled('div')`
    svg {
        vertical-align: top;
        fill: green;
        margin-right: 3px;
    }
`;

const StyledNotConfirmedText = styled('div')`
    svg {
        vertical-align: top;
        fill: red;
        margin-right: 3px;
    }
`;

function PaymentFinished() {
    const [loading, setLoading] = React.useState(true);
    const [loadingConfirmation, setLoadingConfirmation] = React.useState(true);
    const [successImageMarkup, setSuccessImageMarkup] = React.useState(null);
    const [paymentConfirmed, setPaymentConfirmed] = React.useState(false);
    const [orderNumber, setOrderNumber] = React.useState(null);
    const context = React.useContext(Context);
    const confirmationIntervalTime = 1000;
    const confirmationTimeframe = 10000;

    React.useEffect(() => {
        const orderNumber = stripUrlParameter('order_number');
        setOrderNumber(orderNumber);
        urlFetch('/api/orders/success-image', { successCallback: imageSuccessCallback, serverErrorCallback: imageErrorCallback  }, null, 'POST', null, { email: context.userEmail, order_number: orderNumber }, context.token);

        const interval = setInterval(() => {
            if (paymentConfirmed) {
                return;
            }

            urlFetch('/api/orders/payment-confirmed', { successCallback: (response) => { confirmationSuccessCallback(response, interval); }, serverErrorCallback: confirmationErrorCallback }, null, 'POST', null, { email: context.userEmail, order_number: orderNumber }, context.token);

            // For direct confirmation:
            // urlFetch('/api/orders/finish-payment', { successCallback: (response) => { confirmationSuccessCallback(response, interval) }, serverErrorCallback: confirmationErrorCallback }, null, 'POST', null, { email: context.userEmail, order_number: orderNumber }, context.token);
        }, confirmationIntervalTime);

        setTimeout(() => {
            if (interval) {
                clearInterval(interval);
            }

            if (loadingConfirmation) {
                setLoadingConfirmation(false);
            }
        }, confirmationTimeframe)

        return () => {
            clearInterval(interval);
        };
    }, []); // eslint-disable-line

    const confirmationSuccessCallback = (response, interval) => {
        setPaymentConfirmed(response.confirmed);

        if (response.confirmed) {
            setLoadingConfirmation(false);
            clearInterval(interval);
        }
    }

    const confirmationErrorCallback = (response) => {
        setPaymentConfirmed(false);
        setLoadingConfirmation(false);
    }

    const imageSuccessCallback = (response) => {
        setSuccessImageMarkup(response.image);
        setLoading(false);
    }

    const imageErrorCallback = () => {
        setLoading(false);
    }

    const infoText = paymentConfirmed ?
        <StyledConfirmedText>
            <span className='h4'>Thank you for your order <span className='order-number'>{orderNumber}</span>!</span>
            <p>Your payment has been received and is now being processed. If you have questions regarding your order please
                contact us by email to <a href='mailto:brandshop@brainlab.com'>brandshop@brainlab.com</a>. Include your order no. for faster processing.</p>
            <p>An overview of your order has been sent by email to <b>{context.customer.email}</b>.</p>
        </StyledConfirmedText>
        :
        <StyledNotConfirmedText><ErrorIcon/> Your payment could not be confirmed. If you find this to be an error, please contact <a href='mailto:brandshop@brainlab.com'>brandshop@brainlab.com</a>.</StyledNotConfirmedText>;

    return (
        <DefaultPage loading={loading}>
            {orderNumber === null && <Redirect to='/'/>}

            <KeyVisual imageMarkup={successImageMarkup} />

            <ComponentContainer>
                <ContentContainer>
                    <HomeLink/>

                    <StyledTextBox>
                        <div>
                            <p className='h1'>Order successfully completed!</p>
                        </div>
                        { loadingConfirmation && <StyleConfirmationLoadingText><LoadingIcon/>Your payment confirmation is being verified. Please wait for a short moment. Thank you!</StyleConfirmationLoadingText>}
                        { !loadingConfirmation && infoText }
                    </StyledTextBox>
                </ContentContainer>
            </ComponentContainer>
        </DefaultPage>
    );
}

export default PaymentFinished;
