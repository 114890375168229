import React from "react";
import styled from "styled-components";
import LoadingIcon from '../../02_atoms/LoadingIcon';

const StyledPageLoadingContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
`;

const StyledPageLoadingContent = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    color: ${props => props.theme.colors.pink};
    font-weight: ${props => props.theme.fontWeights.medium};
    
    p {
        padding-left: 15px;
    }
`;

function PageLoading() {
    return (
        <StyledPageLoadingContainer>
            <StyledPageLoadingContent>
                <LoadingIcon/>
                <p>Loading...</p>
            </StyledPageLoadingContent>
        </StyledPageLoadingContainer>
    );
}

export default PageLoading;
