import React from "react";
import styled from "styled-components";
import componentSidePaddings from '../../00_utilities/mixins/componentSidePaddings';

const StyledKeyVisual = styled('div')`
    overflow: hidden;
    position: relative;
    line-height: 0;

    .key-visual__image-container {
        position: relative;
        height: 210px;
        z-index: 0;

        @media (min-width: ${props => props.theme.breakpoints.md.breakpoint}px) {
            height: 360px;
        }

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            height: 540px;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .key-visual__text-container {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        left: 0;
        z-index: 1;
        height: 100%;
        ${componentSidePaddings()};
        font-size: 40px;
        line-height: 1.2;
        letter-spacing: -0.1rem;
        font-weight: ${props => props.theme.fontWeights.medium};

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            font-size: 56px;
        }
    }

    .key-visual__text-topline {
        color: ${props => props.theme.colors.white};
        margin: 0;
    }

    .key-visual__text-subline {
        color: ${props => props.theme.colors.pink};
        margin: 0;
    }
`;


function KeyVisual(props) {
    const {imageMarkup, topline, subline} = props;

    if (!imageMarkup) {
        return null;
    }

    return (
        <StyledKeyVisual>
            {
                imageMarkup !== null && <div className='key-visual__image-container' dangerouslySetInnerHTML={{ __html: imageMarkup }}/>
            }
            <div className='key-visual__text-container'>
                <div className='key-visual__text-container-lines-wrapper'>
                    { topline && <p className='key-visual__text-topline'>asdasdasd{topline}</p> }
                    { subline && <p className='key-visual__text-subline'>{subline}</p> }
                </div>
            </div>
        </StyledKeyVisual>
    );
}

export default KeyVisual;
