import React from "react";
import styled from "styled-components";

const StyledFormInputColumn = styled('div')`
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px;
    width: 100%;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        width: 50%;
    }
`;

const FormInputColumn = (props) => {
    return (
        <StyledFormInputColumn className='form-input-column'>{props.children}</StyledFormInputColumn>
    )
};

export default FormInputColumn;
