import React from 'react';
import styled from 'styled-components';
import OrderSliderElement from '../OrderSliderElement';
import { ReactComponent as ArrowIcon} from '../../../icons/empty-arrow-down.svg';

const StyledOrdersSliderContainer = styled('div')`
    position: relative;
    height: 170px;
    overflow: hidden;

    @media (min-width: ${props => props.theme.breakpoints.max.breakpoint}px) {
        height: 125px;
    }
`;

const StyledOrdersSliderArrowsContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
`;

const StyledOrdersSliderArrowContainer = styled('div')`
    margin-top: 40px;
    pointer-events: all;
    
    &:hover {
        cursor: pointer;

        svg path {
            fill: ${props => props.theme.colors.pink};
        }
    }
    
    svg {
        width: 50px;
        height: 50px;
        
        path {
            fill: ${props => props.theme.colors.lightGrey};
        }
    }
    
    &:first-child {
        svg {
            transform: rotate(90deg);
        }
    }
    
    &:last-child {
        svg {
            transform: rotate(-90deg);
        }
    }

    @media (min-width: ${props => props.theme.breakpoints.max.breakpoint}px) {
        margin-top: 0;
    }
`;

const StyledOrdersSliderElementsContainer = styled('div')`
    display: flex;
    position: absolute;
    transition: left 500ms;
    left: ${props => props.offsetLeft};
`;

const StyledOrdersSliderBubblesContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 15px;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
`;

const StyledOrdersSliderBubblesInnerWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const StyledSliderBubble = styled('div')`
    width: ${props => props.active ? 13 : 10}px;
    height: ${props => props.active ? 13 : 10}px;
    border-radius: 100%;
    background-color: ${props => props.active ? props.theme.colors.pink : props.theme.colors.lightGrey};
    
    &:not(:last-child) {
        margin-right: 5px;
    }
`;

function OrderSlider (props) {
    const { orders, maxOrders } = props;
    const [activeOrder, setActiveOrder] = React.useState(0);
    const [elementWidth, setElementWidth] = React.useState(0);
    const containerRef = React.useRef(null);
    const orderElements = [];

    React.useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setElementWidth(containerRef.current.offsetWidth);
            }
        }

        window.addEventListener('resize', handleResize);
        setElementWidth(containerRef.current.offsetWidth);
    }, []);

    if (elementWidth !== 0) {
        for (let i = 0; i < orders.length; i++) {
            orderElements.push(<OrderSliderElement elementWidth={elementWidth} order={orders[i]} key={`order-${orders[i].id}`}/>)
        }
    }

    const slideRight = () => {
        if (activeOrder === maxOrders - 1) {
            return;
        }

        setActiveOrder(activeOrder + 1);
    }

    const slideLeft = () => {
        if (activeOrder === 0) {
            return;
        }

        setActiveOrder(activeOrder - 1);
    }

    const getBubbles = () => {
        const bubbles = [];

        for (let i = 0; i < orders.length; i++) {
            bubbles.push(<StyledSliderBubble active={activeOrder === i} key={`order-slider-bubble-${i}`}/>)
        }

        return bubbles;
    }

    return <StyledOrdersSliderContainer ref={containerRef}>
        {
            orders.length > 1 &&
            <>
                <StyledOrdersSliderArrowsContainer>
                    <StyledOrdersSliderArrowContainer onClick={slideLeft}><ArrowIcon/></StyledOrdersSliderArrowContainer>
                    <StyledOrdersSliderArrowContainer onClick={slideRight}><ArrowIcon/></StyledOrdersSliderArrowContainer>
                </StyledOrdersSliderArrowsContainer>

                <StyledOrdersSliderBubblesContainer>
                    <StyledOrdersSliderBubblesInnerWrapper>
                        { getBubbles() }
                    </StyledOrdersSliderBubblesInnerWrapper>
                </StyledOrdersSliderBubblesContainer>
            </>
        }

        <StyledOrdersSliderElementsContainer offsetLeft={`-${activeOrder * elementWidth}px`}>
            { orderElements }
        </StyledOrdersSliderElementsContainer>
    </StyledOrdersSliderContainer>
}

export default OrderSlider;
